// Show category list
import React, { Component } from 'react';
import webservice from '../../../services/webservice';
import { Api } from '../../../common/variables';
import ManipulatingSwiper from './ManipulatingSwiper';
import './_featured-product.scss';
import { isBrowser } from 'react-device-detect';

// Language
import utils from '../../../common/utils';

// Redux set data
import { connect } from "react-redux";
import { removeFromWishlist, addToWishlist, addItemToCart } from '../../../services/common';
import { setCartCount, setAlertMessage } from '../../../action/commonAction';

class MostPopularList extends Component {
	constructor() {
		super()
		this.state = {
			popularProductsApi: false
		}
	}

	// fetch all most popular products from api
	async componentDidMount() {
		let params = {
			lang: this.props.language,
			vendorID: this.props.vendorInfos.data.id,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		// let popularProducts = await webservice.httpPost( Api.method.getAllProductsHome, params );
		let popularProducts = await webservice.httpPost( Api.method.getFeaturedProducts, params );
		if ( popularProducts.status === 'ok' ) {
			this.setState({
				popularProducts: popularProducts.data,
				popularProductsApi: true,
			})
		}
	}

	// Add to cart item
	addItemToCart = async ( productID, minQty ) => {
		if ( minQty === 0 ) {
			minQty = 1;
		}

		const newdata = await addItemToCart( productID, minQty, this.props.language );
		this.props.setCartCount( newdata.count );
		this.props.setAlertMessage( newdata.message );
	}

	//add to wishlist
	addToWishlist = async ( productID ) => {
		const newdata = await addToWishlist( productID, this.state.popularProducts, this.props.language );

		if ( newdata.productsArray ) {
			this.setState({
				popularProducts: newdata.productsArray,
				popularProductsApi: newdata.productsApi
			});
		}
		this.props.setAlertMessage( newdata.message );
	}

	//remove from wishlist
	removeFromWishlist = async ( productID ) => {
		const newdata = await removeFromWishlist( productID, this.state.popularProducts, this.props.language );

		this.setState({
			popularProducts: newdata.productsArray,
			popularProductsApi: newdata.productsApi
		});
		this.props.setAlertMessage( newdata.message );
	}

	renderMostPopular() {
		const featured = [];
		for (let i = 0; i < 6; i++) {
			// Try avoiding the use of index as a key, it has to be unique!
			featured.push(
				<div className="col-lg-2 col-md-3 col-sm-4 col-6" key={i}>
					<div className="featuredProducts" style={{boxShadow: 'none', alignItems: 'center',}}>
						<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px',}}>
							<img src={ require('../../../assets/images/placeholder.png') } alt="productsimage" style={{width: '40px'}} />
						</div>
						<div style={{width: '70%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', marginBottom: '5px'}}>
						</div>
						<div style={{width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px',}}>
						</div>
					</div>
				</div>
			);
		}
		return featured;
	}

	render() {
		return (
			<section className="section most-popular-product">
				<div className="wrapper wrapper_1200">
					<h2 className="catTitle title-withline"><span className="wrapwhite">{ utils.Language( 'lang_featuredProducts', this.props.language ) }</span></h2>
					{
						( this.state.popularProductsApi ) ?
						<div>
						{
							( this.state.popularProducts.length > 0 ) ?
							<ManipulatingSwiper popularProducts={this.state.popularProducts} addToWishlist={ this.addToWishlist } removeFromWishlist={ this.removeFromWishlist } addItemToCart={ this.addItemToCart } language={ this.props.language } userType={ this.props.userType } />
							: <div className="not-available">{ utils.Language( 'lang_nofeaturedProducts', this.props.language ) }</div>
						}
						</div>
						:
						<div className="row" style={{flexWrap: 'nowrap', overflow: 'hidden'}}>
							{ this.renderMostPopular() }
						</div>
					}
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: ( count ) => dispatch( setCartCount( count ) ),
	setAlertMessage: ( message ) => dispatch( setAlertMessage( message ) )
});

export default connect( mapStateToProps, mapDispatchToProps )(MostPopularList);