var host          = window.location.hostname;
var protocol      = window.location.protocol;
var apiUrl        = protocol + "//api.omvalley.websearchpro.net/v1/";
var customUrl     = protocol + "//omvalley.websearchpro.net/";
var backEndapiUrl = protocol + "//omvalley.websearchpro.net/";

if ( "omvalley.websearchpro.net" === host || "omvalley.websearchpro.net" === host ) {
	apiUrl = protocol + "//api.omvalley.websearchpro.net/v1/";
	backEndapiUrl = protocol + "//omvalley.websearchpro.net/";
	customUrl = host;
} else if ( "dev.omvalley.websearchpro.net" === host ) {
	apiUrl = protocol + "//apidev.omvalley.websearchpro.net/v1/";
	backEndapiUrl = protocol + "//dev.omvalley.websearchpro.net/";
	customUrl = host;
} else if ( "www.gharbanaudev.com" === host ) {
	apiUrl = protocol + "//apidev.omvalley.websearchpro.net/v1/";
	backEndapiUrl = protocol + "//www.gharbanaudev.com/";
	customUrl = host;
} else if ( "localhost" === host ) {
	// apiUrl = "https://apidev.omvalley.websearchpro.net/v1/";
	apiUrl = "https://api.omvalley.websearchpro.net/v1/";
	// backEndapiUrl = "http://localhost:3000/";
	// customUrl = 'localhost';
} else {
	apiUrl = "https://api.omvalley.websearchpro.net/v1/";
	backEndapiUrl = "https://" + host + '/';
}

const Api = {
	baseurl : apiUrl,
	customUrl : customUrl,
	backEndapiUrl : backEndapiUrl,
	method: {
		getHomeSlider: 'app-data',
		getFeaturedCategories: 'get-featured-categories',
		getFeaturedCategoriesMobile: 'get-featured-categories-mobile',
		getFeaturedProducts: 'get-featured-products',
		getMostPopularProducts: 'get-most-popular-products',
		getProductBrands: 'get-product-brands',
		getAllCategories: 'get-all-categories',
		getProductsByCategory: 'get-products-by-category',
		getBrandsByCategorySlug: 'get-brands-by-category',
		getProductsByFilter: 'filter-products-by-brand',
		getAllProducts: 'get-all-products',
		login: 'login',
		userSignup: 'user-signup',
		changeUserPassword: 'change-user-password',
		sendResetCodeToEmail: 'user-password-reset',
		resetUserPassword: 'reset-user-password',
		validateResetCode: 'validate-user-resetCode',
		editUserProfile: 'edit-user-profile',
		getUserOrders: 'get-user-orders',
		getOrderDetails: 'get-order-details',
		cancelUserOrder: 'cancel-user-order',
		getProductDetail: 'get-product-detail-new',
		addToCart: 'add-to-cart',
		updateCartItem: 'update-cart',
		cartItems: 'cart-items',
		removeCartItem: 'remove-cart-item',
		getUserAddress: 'get-user-address',
		saveAddress: 'save-user-addressbook',
		placeOrder: 'place-order',
		getCartCount: 'get-cart-count',
		getDataBySearch: 'search',
		getPageDetail: 'get-page-detail',
		addToWishlist: 'add-to-wishlist',
		listWishlistItem: 'list-wishlist-item',
		deleteWishlistItem: 'delete-wishlist-item',
		getFindSuppliers: 'get-suppliers-list',
		getSupplierDetails: 'get-suppliers-details',
		getSupplierPageDetail: 'get-supplierpage-detail',
		getSellerProducts: 'get-seller-products',
		// getCategoriesDropdown: 'get-categories-dropdown-product',
		getQuestionAnswerList: 'get-question-answer-list',
		postProductQuestion: 'post-product-question',
		getUnansweredQuestionDetail: 'get-unanswered-question-detail',
		getAllProductsHome: 'get-all-products-home',
		getLatestProducts: 'get-latest-products',
		getRelatedProducts: 'get-related-products',
		getProductImages: 'get-product-images',
		getProductMoreDetails: 'get-product-more-details',
		AuthfromFacebook: 'authenticate-through-facebook',
		getState: 'get-state',
		getRegion: 'get-region',
		getCategoriesDropdownWithProduct: 'get-categories-dropdown-withproduct',
		getUserLoginHistory: 'user-login-history',
		getAllNews: 'get-all-news',
		getNewsDetail: 'get-news-detail',
		getSiteSettings: 'get-site-settings',
		checkValidateCode: 'validate-code',
		getHeaderNavMenu: 'get-header-nav-menu',
		getFooterNavMenu: 'get-footer-nav-menu',
		getVendorIDs: 'get-vendorIDs',
		getSupplierPageLanding: 'get-supplierpage-as-landing',
		getSearchSuggestion: 'get-search-suggestion',
		getSupplierSearchSuggestion: 'get-supplier-suggestion',
		getPopularSearchKeywords: 'get-popular-search-words',
		// getDataBySearch: 'site-search',
	}
}

export {
	Api
}

