import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import Banner from './content/banner/banner';
import FeaturedCategory from './content/featured-category/featured-category';
import FeaturedProducts from './content/featured-products/featured-products';
import MostPopularProducts from './content/most-popular-products/most-popular-products';
import FeaturedBrands from './content/featured-brands/featured-brands';
// Suppliers pages
import SupplierBanner from '../suppliers/template/banner';
import SupplierFeaturedCategory from '../suppliers/template/featured-category';
import SupplierNewProducts from '../suppliers/template/new-products';
import SupplierFeaturedProducts from '../suppliers/template/featured-product/featured-products';
import SupplierPageLanding from '../suppliers/SupplierPageLanding';

import webservice from '../services/webservice';
import { Api } from '../common/variables';

// Redux set data
import { connect } from "react-redux";

const MainPageList = props => {
	if ( Api.customUrl ) {
		return (
			<div className="mainpage">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>Om Valley Liquor - A place for your thirst</title>
					<meta name="keywords" content={ props.metaKeywords ? props.metaKeywords : 'Gharbanau, construction, materials' } />
					<meta
					name="description"
					content={ props.metaDescription ? props.metaDescription : 'A place for your thirst' }
					/>
				</MetaTags>

				{ ( props.homeH1tag ) ? <h1 className="home-h1-tag">{props.homeH1tag}</h1> : null }				
				<div className="featured_category-mobile">
					<FeaturedCategory />
				</div>
				<Banner googleAds={ props.googleAds } googleAdsMobile={ props.googleAdsMobile } />
				<div className="featured_category-desktop">
					<FeaturedCategory />
				</div>
				<FeaturedProducts />
				{/* <AdsSection /> */}
				<MostPopularProducts />
				<FeaturedBrands />
			</div>
		)
	} else {
		return (
			<div className="supplierPage mainpage">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{props.metaTitle}</title>
					<meta name="keywords" content={ props.metaKeywords ? props.metaKeywords : '' } />
					<meta
					name="description"
					content={ props.metaDescription ? props.metaDescription : '' }
					/>
				</MetaTags>

				{ ( props.homeH1tag ) ? <h1 className="home-h1-tag">{props.homeH1tag}</h1> : null }
				{
					( true === props.use_product_as_landing ) ?
					<>
						<SupplierBanner />
						<SupplierFeaturedCategory />
						<SupplierNewProducts />
						<SupplierFeaturedProducts />
					</>
					:
					<SupplierPageLanding />
				}
			</div>
		)
	}
};

class MainPage extends Component {
	constructor() {
		super()
		this.state = {
			metaDescription : '',
			metaKeywords : '',
			homeH1tag : '',
			googleAds : '',
			googleAdsMobile : '',
		};
	}

	async componentDidMount() {
		var x = document.getElementById( "footer_bottom-right" );
		if ( "none" === x.style.display ) {
			x.style.display = "block";
		}

		window.scrollTo({top: 0});

		let params = {
			lang     : this.props.language,
			vendorID : this.props.vendorInfos.data.id
		}

		let getSiteSettings = await webservice.httpPost( Api.method.getSiteSettings, params );
		if ( 'ok' === getSiteSettings.status ) {
			this.setState({
				metaDescription : getSiteSettings.data.meta_description,
				metaKeywords : getSiteSettings.data.meta_keywords,
				metaTitle : getSiteSettings.data.meta_title,
				homeH1tag : getSiteSettings.data.home_h1_tag,
				googleAds : getSiteSettings.data.google_ads_home_banner,
				googleAdsMobile : getSiteSettings.data.google_ads_home_banner_mobile,
			});
		}
	}

	render() {
		return (
			<MainPageList
			homeH1tag={ this.state.homeH1tag }
			use_product_as_landing={ this.props.vendorInfos.data.use_product_as_landing }
			metaTitle={ this.state.metaTitle ? this.state.metaTitle : 'Gharbanau, construction, materials' }
			metaKeywords={ this.state.metaKeywords ? this.state.metaKeywords : 'Gharbanau, construction, materials' }
			metaDescription={ this.state.metaDescription ? this.state.metaDescription : 'A place for your thirst' }
			googleAds={ this.state.googleAds }
			googleAdsMobile={ this.state.googleAdsMobile }
			/>
		);
	}
}

const mapStateToProps = state => ({
	...state
})

export default connect( mapStateToProps, null )(MainPage);
