// Show category list
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { Api } from '../../../common/variables';
import webservice from '../../../services/webservice';
import noImage from '../../../assets/images/no-image-available.png';
import './_product.scss';
import { isBrowser } from 'react-device-detect';
import AdsSection from '../../../components/content/adsection/adsection';

// Language
import utils from '../../../common/utils';

// Redux set data
import { connect } from "react-redux";
import { removeFromWishlist, addToWishlist, addItemToCart } from '../../../services/common';
import { setCartCount, setAlertMessage } from '../../../action/commonAction';

// Lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';

class FeaturedProductsList extends Component {
	constructor() {
		super()
		this.state = {
			featuredProductsApi : false,
			visible : false,
			loader  : false,
			cartmessage : null,
			offset : 0,
			totalRecords: 0,
			clicked: false,
			hideLoadMoreBtn: false,
			limit: 24
		};
	}


	// fetch all featured products from api
	async componentDidMount() {
		let params = {
			limit: this.state.limit,
			lang: this.props.language,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let featuredProducts = await webservice.httpPost( Api.method.getAllProductsHome, params );

		if ( 'ok' === featuredProducts.status ) {
			this.setState({
				featuredProducts : featuredProducts.data,
				offset : featuredProducts.data.length,
				featuredProductsApi : true,
				totalRecords: featuredProducts.total_records,
			})
		}
	}

	// Add to cart item
	addItemToCart = async ( productID, minQty ) => {
		if ( minQty === 0 ) {
			minQty = 1;
		}

		const newdata = await addItemToCart( productID, 1, this.props.language );
		this.props.setCartCount( newdata.count );
		this.props.setAlertMessage( newdata.message );
	}

	//add to wishlist
	addToWishlist = async ( productID ) => {
		const newdata = await addToWishlist( productID, this.state.featuredProducts, this.props.language );

		if ( newdata.productsArray ) {
			this.setState({
				featuredProducts: newdata.productsArray,
				featuredProductsApi: newdata.productsApi
			});
		}
		this.props.setAlertMessage( newdata.message );
	}

	//remove from wishlist
	removeFromWishlist = async ( productID ) => {
		const newdata = await removeFromWishlist( productID, this.state.featuredProducts, this.props.language );

		this.setState({
			featuredProducts: newdata.productsArray,
			featuredProductsApi: newdata.productsApi
		});
		this.props.setAlertMessage( newdata.message );
	}

	//remove from loadmore
	loadMoreProducts = async () => {
		this.setState({
			clicked: true,
			loader: true,
		});

		let params = {
			limit: this.state.limit,
			lang: this.props.language,
			Offset: this.state.offset,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let LoadmoreAllproducts = await webservice.httpPost( Api.method.getAllProductsHome, params );

		if ( 'ok' === LoadmoreAllproducts.status ) {
			//update all products
			let UpdateProducts = [...this.state.featuredProducts];
			let loadMoreProductLists = UpdateProducts.concat( LoadmoreAllproducts.data );

			if ( loadMoreProductLists.length >= LoadmoreAllproducts.total_records ) {
				this.setState({
					hideLoadMoreBtn: true,
				})
			}

			this.setState({
				featuredProducts: loadMoreProductLists,
				offset: loadMoreProductLists.length,
				featuredProductsApi: true,
				clicked: false,
				loader: false,
			})
		}
	}

	renderFeatured() {
		const featured = [];
		for ( let i = 0; i < 12; i++ ) {
			// Try avoiding the use of index as a key, it has to be unique!
			featured.push(
				<div className="col-lg-2 col-md-3 col-sm-4 col-6" key={i}>
					<div className="featuredProducts" style={{boxShadow: 'none', alignItems: 'center',}}>
						<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px',}}>
							<img src={ require('../../../assets/images/placeholder.png') } alt="productsimage" style={{width: '40px'}} />
						</div>
						<div style={{width: '70%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', marginBottom: '5px'}}></div>
						<div style={{width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px',}}></div>
					</div>
				</div>
			);
		}
		return featured;
	}

	render() {
		return (
			<section className="section home-product_wrapper bgWhite">
				<div className="wrapper wrapper_1200">
					<div className="featureProduct_wrapper">
						<h2 className="catTitle">{ utils.Language( 'lang_newProducts', this.props.language ) }</h2>
						<div className="row">
							{
								( this.state.featuredProductsApi ) ?
									this.state.featuredProducts.map( (products,index) => {
										return(
										<div className="col-lg-2 col-md-3 col-sm-4 col-6" key={products.id}>
											<div className="featuredProducts " id={ products.id }>
												{
													( products.is_verified === '1' || products.is_verified === 1 ) ?
														<div className="verified small-verified"><div className="verified-div"><img src={require('../../../assets/images/certifications.png')} alt="reward" className="verified-logo" /> { utils.Language( 'lang_verified', this.props.language ) }</div></div>
													: null
												}
												{
													( 'seller' !== this.props.userType ) ?
													<div className="wishlist-div">
														{
															( true === products.wishlistStatus ) ?
															<div className="wishlist wishlist-added" title="Wishlist" onClick={ () => { this.removeFromWishlist( products.id ) } }><span className="icon-heart-black"></span></div>
															:
															<div className="wishlist" title="Wishlist" onClick={() => { this.addToWishlist( products.id ) } }><span className="icon-heart-black" ></span></div>
														}
													</div>
													: null
												}
												<Link to={'/products/' + encodeURIComponent( products.slug )} className="link">
													<div data-mh className="imgWrapper" style={{ }}>
														<LazyLoadImage className="product_img" src={ products.imageUrl ? products.imageUrl : noImage } alt={ products.name } />
													</div>
													<div data-mh className="productDetail">
														<div className="proName">{products.name}</div>
														{
															( products.company_name || products.addressList ) ?
															<div className="supplierdetails">
																<span className="company">{ products.company_name }</span>
																<span className="address">{ products.addressList }</span>
															</div>
															: null
														}

														{/* {
															( products.distance ) ?
															<div className="proDistance"><span className="icon icon-map-marker-alt"></span> { Number.parseFloat( products.distance ).toFixed(2) } { utils.Language( 'lang_' + products.distanceType, this.props.language ) }</div>
															: null
														} */}
														
														<div className="priceSec">
														{
															( products.regularPrice !== products.salePrice && products.salePrice !== '0.00'  ) ?
																<div><span className="regularprice">{ utils.Language( 'lang_currency', this.props.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
																<span className="saleprice">
																	{ utils.Language( 'lang_currency', this.props.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.salePrice ) }
																	{
																		( products.salePriceValidTill ) ?
																			<div className="singleBrands validtilldate">
																				<span className="smallText">({ utils.Language( 'lang_priceValidTill', this.props.language ) } { products.salePriceValidTill })</span>
																			</div>
																		: null
																	}
																</span></div>
															:
															<span className="saleprice">{ utils.Language( 'lang_currency', this.props.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
														}
														</div>
													</div>
												</Link>
												<div className="starrating-div">
												{
													( products.rating ) ?
														<StarRatings
														rating={ parseFloat( products.rating ) }
														starRatedColor="#ff6f00"
														starHoverColor="#ff6f00"
														numberOfStars={5}
														name='rating'
														starDimension="20px"
														starSpacing="0"
														/>
													: null
												}
												</div>
												{
													( 'seller' !== this.props.userType ) ?
														<div data-mh className="productDetail productDetailnew">
															<button type="button" className="btn btn-danger btn-add-cart" onClick={() => { this.addItemToCart( products.id, parseInt( products.minimum_quantity ) ) }}><span className="icon-cart-add"></span>
															{ utils.Language( 'lang_addToCart', this.props.language ) }</button>
														</div>
													: null
												}
											</div>
										</div>
										)
									})
								:
								this.renderFeatured()
							}
						</div>
						{
							( this.state.loader ) ? 
							<div className="loader">Loading...</div>
							: null
						}
						{
							( false === this.state.hideLoadMoreBtn && false === this.state.loader ) ?
							<div className="btnWrapper" id="load-more-new">
								<button type="button" className="btn btn-outline btn-outline-default btn-radius-20 btn-small-curve" onClick={ this.loadMoreProducts } disabled={ this.state.clicked }>{ utils.Language( 'lang_viewMore', this.props.language ) }</button>
							</div>
							: null
						}
					</div>
				</div>

				{
					this.props.data === 404 ? null :
					<div className="adSection">
						<ins className="adsbygoogle"
						style={{display: 'block'}}
						data-ad-client="ca-pub-5591747360267316"
						data-ad-slot="3461896388"
						data-ad-format="auto"
						data-full-width-responsive="true"></ins>
						<script>
						(adsbygoogle = window.adsbygoogle || []).push({});
						</script>

						<AdsSection />
					</div>
				}
			</section>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: ( count ) => dispatch( setCartCount( count ) ),
	setAlertMessage: ( message ) => dispatch( setAlertMessage( message ) )
});

export default connect( mapStateToProps, mapDispatchToProps )(FeaturedProductsList);