// Show category list
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import webservice from '../../../services/webservice';
import { Api } from '../../../common/variables';
import noImage from '../../../assets/images/no-image-available.png';
import { isBrowser } from 'react-device-detect';
import './_category.scss';

// Language
import utils from '../../../common/utils';

// Redux set data
import { connect } from "react-redux";

// Lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';

class CategoryList extends Component {
	constructor() {
		super()
		if ( true === isBrowser ) {
			this.state = {
				CategoriesApi: false,
				limit: 12
			}
		}  else {
			this.state = {
				CategoriesApi: false,
				limit: 9999
			}
		}
	}

	// fetch all categories from api
	async componentDidMount() {
		let params = {
			limit : this.state.limit,
			lang  : this.props.language
		}
		let featured = await webservice.httpPost( Api.method.getFeaturedCategoriesMobile, params );
		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
			featured = await webservice.httpPost( Api.method.getFeaturedCategories, params );
		} 

		
		//let categories = await webservice.httpPost( Api.method.getAllCategories, params );
		if ( featured.status === 'ok' ) {
			this.setState({
				categories: featured.data,
				CategoriesApi: true,
			})
		}
	}

	renderFeatured() {
		const featured = [];
		for (let i = 0; i <= featured.count; i++) {
			// Try avoiding the use of index as a key, it has to be unique!
			featured.push(
				<div className="categoryList" key={i}>
					<div className="imgWrapper" style={{boxShadow: 'none', alignItems: 'center', flexDirection: 'column',}}>
						<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px',}}>
							<img src={ require('../../../assets/images/placeholder.png') } alt="productsimage" style={{width: '40px'}} />
						</div>
						<div style={{width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px',alignItems: 'center',}}></div>
					</div>
				</div>
			);
		}
		return featured;
	}

	render() {
		return (
			<section className="section category_wrapper">
				<div className="wrapper wrapper_1200">
					<h2 className="catTitle"><Link to={'/product-categories'} className="link">{ utils.Language( 'lang_categories', this.props.language ) }</Link></h2>
					<div className="row">
						<div className="col-12">
							<div className="category_wrapper-div">
								<div className="d-flex">
								{
									( this.state.CategoriesApi ) ?
										this.state.categories.map( (category,index) => {
											// Check image url
											var imageUrl = noImage;
											if ( category.imageUrl ) {
												imageUrl = category.imageUrl;
											} else if ( category.fullimageUrl ) {
												imageUrl = category.fullimageUrl;
											}

											return(
												<div className="categoryList" key={category.id}>
													<Link to={'/category/'+encodeURIComponent( category.slug )} className="link">
														<div className="imgWrapper">
															<LazyLoadImage src={ 'https://cdn.shortpixel.ai/client/q_glossy,ret_img/'+imageUrl } alt={category.name} />
														</div>
														<span>{category.name}</span>
													</Link>
												</div>
											)
										})
									:
									this.renderFeatured()
								}
								</div>
								<div className="btnWrapper" id="">
									{/* <button type="button" className="btn btn-outline btn-outline-default btn-radius-20 btn-small-curve">View all categories</button> */}
									<a href={`/product-categories`} className="btn btn-outline btn-outline-default btn-radius-20 btn-small-curve">{ utils.Language( 'lang_ViewAllCategories', this.props.language ) }</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*<div className="btnWrapper">
					<Link to="/product-categories" className="btn btn-outline btn-outline-default btn-radius-20 btn-small-curve">{ utils.Language( 'lang_viewAll', this.props.language ) }</Link>
				</div>*/}
			</section>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(CategoryList);