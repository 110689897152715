import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from "react-redux";
import Header from './components/header';
import Footer from './components/footer';
import SupplierHeader from './suppliers/supplierheader';
import SupplierFooter from './suppliers/supplierfooter';
import NavRoute from './services/router.js';
import { getMyLocation, getVendorInfos } from './services/common';
import { setGeoLocation, setVendorInfos } from './action/commonAction';
import CookieConsent from "react-cookie-consent";
import { isMobile, isIOS } from 'react-device-detect';
import Loader from './common/Loader';
import { Api } from './common/variables';

import './assets/styles/_app.scss';
import 'swiper/css/swiper.css';

const MainContent = props => {
	if ( Api.customUrl ) {
		return (
			<div className="mainWrapper" style={{ '--primary-color': props.primaryColor, '--secondary-color': props.secondaryColor }}>
				{ ( '/latest-product' !== window.location.pathname ) ? <Header /> : null }
				<main className="mainClass">
					{ ( props.initDone && props.initDoneVendorID ) ? <NavRoute /> : null }
				</main>
				{ ( '/latest-product' !== window.location.pathname && props.initDone && props.initDoneVendorID) ? <Footer /> : null }
			</div>
		)
	} else {
		return (
			<div className="mainWrapper custom-domain" style={{ '--primary-color': props.primaryColor, '--secondary-color': props.secondaryColor }}>
				{ ( '/latest-product' !== window.location.pathname && props.initDoneVendorID ) ? <SupplierHeader /> : null }
				<main className="mainClass">
					{ ( props.initDone && props.initDoneVendorID ) ? <NavRoute /> : <Loader /> }
				</main>
				{ ( '/latest-product' !== window.location.pathname && props.initDone && props.initDoneVendorID ) ? <SupplierFooter /> : null }
			</div>
		)
	}
};

// const convertHexToRGBA = ( hexCode ) => {
// 	let hex = hexCode.replace('#', '');

// 	if (hex.length === 3) {
// 		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
// 	}

// 	const r = parseInt(hex.substring(0, 2), 16);
// 	const g = parseInt(hex.substring(2, 4), 16);
// 	const b = parseInt(hex.substring(4, 6), 16);

// 	return `${r},${g},${b}`;
// };

class App extends Component {
	constructor( props ) {
		super( props )
		this.state = {
			initDone: false,
			initDoneVendorID: false,
			primaryColor: '8,91,141',
			secondaryColor: '22,172,75'
		}
	}

	// fetch all featured products from api
	componentDidMount () {
		getVendorInfos().then(resp=>{
			// console.log('resp', resp);
			if ( 'error' === resp.status ) {
				//window.location.href = 'https://omvalley.websearchpro.net/';
			}
			this.props.setVendorInfos( resp );
			this.setState({
				initDoneVendorID: true,
				// primaryColor: convertHexToRGBA( resp.data.Primary_Color ),
				// secondaryColor: convertHexToRGBA( resp.data.Secondary_Color )
			})
		}).catch(ex=>{
			this.props.setVendorInfos( ex );
			this.setState({
				initDoneVendorID: true,
			})
		});

		let geoLocation ={};
		getMyLocation().then(resp=>{
			geoLocation=resp;
			this.props.setGeoLocation( geoLocation );
			this.setState({
				initDone: true,
			})
		}).catch(ex=>{
			geoLocation=ex;
			this.props.setGeoLocation( geoLocation );
			this.setState({
				initDone:true
			})
		})

		window.onpopstate = (e) => {
			window.scrollTo({top: 0});
			//window.location.reload();
		}
	}

	render() {
		return (
			<Router>
			{/* { ( isMobile && ( 'omvalley.websearchpro.net' === Api.customUrl || 'dev.omvalley.websearchpro.net' === Api.customUrl || 'omvalley.websearchpro.net' === Api.customUrl ) ) ? 
				<CookieConsent location="top"
				buttonText="X"
				cookieName="appBlockCookie"
				style={{ background: "#fff", color: "#4e503b" }}
				buttonStyle={{ color: "#4e503b", fontSize: "13px", width: "20px" }}
				expires={0}
				hideOnAccept={ true }
				>
				<div className="appOpenBlock">
				<div className="appOpenBlockLeft">
					<img src={require('./assets/images/favicon.png')} 
					alt="Logo" className="appBlockLogo" />
					<div>
						<span>View in Gharbanau { ( isIOS ) ? 'iOS app' : 'Android app' }</span>
						<span className="smallText">Construction materials/home decor</span>
					</div>
				</div>
				<a id="appLink" href="gharbanauapp://home"> Open </a>
				</div>
				</CookieConsent>
				: null } */}
				<MainContent
				initDone={ this.state.initDone }
				initDoneVendorID={ this.state.initDoneVendorID }
				primaryColor={ this.state.primaryColor }
				secondaryColor={ this.state.secondaryColor }
				/>
			</Router>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setGeoLocation: ( payload ) => dispatch( setGeoLocation( payload ) ),
	setVendorInfos: ( VendorInfos ) => dispatch( setVendorInfos( VendorInfos ) )
});

export default connect( null, mapDispatchToProps )( App );