// SingleSupplier js
import React, { Component } from 'react';
import webservice from '../services/webservice';
import StarRatings from 'react-star-ratings';
import { Api } from '../common/variables';
import { Link } from 'react-router-dom';
import noImage from '../assets/images/no-image-available.png';
import Pagination from "../components/Pagination";
import MetaTags from 'react-meta-tags';
import NewProduct from '../components/NewProducts';
import Lightbox from 'react-image-lightbox';
import { isMobile, isBrowser } from 'react-device-detect';
// import InfiniteCarousel from 'react-leaf-carousel';
// Import Swiper React components
import Swiper from 'react-id-swiper';

import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon } from "react-share";

// Lazy load
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// Langugae
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";
import { removeFromWishlist, addToWishlist, addItemToCart } from '../services/common';
import { setCartCount, setAlertMessage } from '../action/commonAction';

class SingleSupplier extends Component {
	constructor() {
		super()
		this.state = {
			supplierApi: false,
			is_subscribed: false,
			categoryApi: false,
			initDoneLoader: true,
			active: false,
			isUserLogin: false,
			Offset: 0,
			photoIndex: 0,
			isOpen: false,
			limit: 15,
			totalProducts: 0,
			name: null,
			categoryid: null,
			cartmessage: null,
			country: null,
			distance: null,
			contact_phone: null,
			city: null,
			distanceType: null,
			district: null,
			logo_fullpath: null,
			rating: 0,
			region: null,
			street: null,
			contact_email: null,
			vendor_phone: null,
			vendor_phone_alt: null,
			address_list: null,
			vendor_verified: 0,
			paginationtype: 'list',
			bannerImage: [],
			facebook: '',
			instagram: '',
			twitter: '',
			url: '',
			aboutcompany: '',
			products: '',
			whychooseus: '',
			contactus: '',
			home: '',
			pageslists: [],
			Longitude: null,
			Latitude: null,
			shows: false,
		}
	}

	async componentDidMount() {
		var str = Api.backEndapiUrl;
		var strrepl = str.replace(/\/+$/, '');
		var pretyurl = strrepl + this.props.match.url.replace('/products', '');
		this.setState({ url: pretyurl.replace('/\/+$/', '') });
		// this.setState({ url : strrepl + this.props.match.url });

		let suppliersSlug = this.props.match.params.slug;

		const userdetails = localStorage.getItem('userdetail');
		if (userdetails) {
			const details = JSON.parse(userdetails);
			if (details.userID) {
				this.setState({
					isUserLogin: true,
				});
			}
		}

		// parameter
		let params = {
			SupplierSlug: suppliersSlug,
			limit: this.state.limit,
			Offset: this.state.Offset,
			lang: this.props.language,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		// fetch api data
		let getSellerProducts = await webservice.httpPost(Api.method.getSellerProducts, params);
		let getSupplierDetails = await webservice.httpPost(Api.method.getSupplierDetails, params);

		// Suppliers Products
		if (getSellerProducts.status === 'ok') {
			this.setState({
				supplierApi: true,
				initDoneLoader: false,
				getSellerProducts: getSellerProducts.data,
				Offset: getSellerProducts.data.length,
				totalProducts: getSellerProducts.total_records
			})

			if (getSellerProducts.total_records > 0) {
				let getCategories = await webservice.httpPost(Api.method.getCategoriesDropdownWithProduct, { SellerSlug: suppliersSlug, lang: this.props.language });

				if (getCategories.status === 'ok') {
					this.setState({
						getCategoriesDropdown: getCategories.data,
						categoryApi: true,
					})
				}
			}
		}

		// Suppliers Details
		if (getSupplierDetails.status === 'ok') {
			if (isMobile) {
				// window.location = "gharbanauapp://SupplierProductList/" + getSupplierDetails.data.id;
				if (document.getElementById("appLink")) {
					document.getElementById("appLink").href = "gharbanauapp://SupplierProductList/" + getSupplierDetails.data.id;
				}
			}

			this.setState({
				name: getSupplierDetails.data.name,
				country: getSupplierDetails.data.country,
				distance: getSupplierDetails.data.distance,
				contact_phone: getSupplierDetails.data.contact_phone,
				city: getSupplierDetails.data.city,
				distanceType: getSupplierDetails.data.distanceType,
				district: getSupplierDetails.data.district,
				logo_fullpath: getSupplierDetails.data.logo_fullpath,
				rating: getSupplierDetails.data.rating,
				region: getSupplierDetails.data.region,
				street: getSupplierDetails.data.street,
				vendor_verified: getSupplierDetails.data.vendor_verified,
				contact_email: getSupplierDetails.data.contact_email,
				is_subscribed: getSupplierDetails.data.is_subscribed,
				contact_fullname: getSupplierDetails.data.contact_fullname,
				vendor_phone: getSupplierDetails.data.vendor_phone,
				vendor_file: getSupplierDetails.data.vendor_file,
				vendor_phone_alt: getSupplierDetails.data.vendor_phone_alt,
				address_list: getSupplierDetails.data.addressList,
				longitude: getSupplierDetails.data.longitude,
				latitude: getSupplierDetails.data.latitude,
				bannerImage: getSupplierDetails.data.BannerArrayImage,
				facebook: getSupplierDetails.data.facebook,
				instagram: getSupplierDetails.data.instagram,
				twitter: getSupplierDetails.data.twitter,
				aboutcompany: getSupplierDetails.data.AboutCompany,
				whychooseus: getSupplierDetails.data.WhyChooseUs,
				products: '1',
				contactus: getSupplierDetails.data.ContactUs,
				pageslists: getSupplierDetails.data.PagesLists,				
				supplierApi: true,
			})

			var myobj = document.getElementById("footer_bottom-right");
			if (true === getSupplierDetails.data.is_subscribed) {
				myobj.style.display = 'none';
			} else {
				myobj.style.display = 'block';
			}
		}
	}

	filterCategories = async (catid) => {
		this.setState({
			active: true,
			categoryid: catid,
			shows: false
		});

		let suppliersSlug = this.props.match.params.slug;
		let params = {
			Offset: 0,
			CategoryId: catid,
			SupplierSlug: suppliersSlug,
			limit: this.state.limit,
			lang: this.props.language,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let getSellerProducts = await webservice.httpPost(Api.method.getSellerProducts, params);

		if (getSellerProducts.status === 'ok') {
			this.setState({
				supplierApi: true,
				getSellerProducts: getSellerProducts.data,
				Offset: getSellerProducts.data.length,
				paginationtype: 'filter',
				totalProducts: getSellerProducts.total_records
			})
			window.scrollTo({ top: 0, behavior: 'smooth' });
			this.onPageChanged({ currentPage: 1, pageLimit: this.state.limit });
		}

	}

	onPageChanged = async (data) => {
		let suppliersSlug = this.props.match.params.slug;

		let params = {
			SupplierSlug: suppliersSlug,
			limit: this.state.limit,
			lang: this.props.language,
			CategoryId: this.state.categoryid,
			Offset: (data.currentPage - 1) * data.pageLimit,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let getSellerProducts = await webservice.httpPost(Api.method.getSellerProducts, params);

		if (getSellerProducts.status === 'ok') {
			this.setState({
				getSellerProducts: getSellerProducts.data,
				totalProducts: getSellerProducts.total_records,
				totalPages: getSellerProducts.total_pages,
				Offset: getSellerProducts.data.length,
				supplierApi: true
			})

			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	// Add to cart item
	addItemToCart = async (productID, minQty) => {
		if (minQty === 0) {
			minQty = 1;
		}

		const newdata = await addItemToCart(productID, minQty, this.props.language);
		this.props.setCartCount(newdata.count);
		this.props.setAlertMessage(newdata.message);
	}

	//add to wishlist
	addToWishlist = async (productID) => {
		const newdata = await addToWishlist(productID, this.state.getSellerProducts, this.props.language);

		if (newdata.productsArray) {
			this.setState({
				supplierApi: newdata.productsApi,
				getSellerProducts: newdata.productsArray
			});
		}
		this.props.setAlertMessage(newdata.message);
	}

	//remove from wishlist
	removeFromWishlist = async (productID) => {
		const newdata = await removeFromWishlist(productID, this.state.getSellerProducts, this.props.language);

		this.setState({
			supplierApi: newdata.productsApi,
			getSellerProducts: newdata.productsArray
		});
		this.props.setAlertMessage(newdata.message);
	}

	renderSuppliers() {
		const suppliers = [];
		for (let i = 0; i < 15; i++) {
			// Try avoiding the use of index as a key, it has to be unique!
			suppliers.push(
				<div className="col-lg-3 col-sm-4 col-6" key={i}>					
					<div className="categoryList" style={{ boxShadow: 'none', alignItems: 'center', }}>
						<div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px', }}>
							<img src={require('../assets/images/placeholder.png')} alt="productsimage" style={{ width: '30px' }} />
						</div>
						<div style={{ width: '70%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', marginBottom: '5px' }}>
						</div>
						<div style={{ width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', }}>
						</div>
					</div>
				</div>
			);
		}
		return suppliers;
	}

	showLoginPopup() {
		document.getElementById("LoginPopup").click();
	}

	render() {
		const { photoIndex, isOpen } = this.state;

		const params = {
			navigation: {
				nextEl: '.swiper-button-next.swiper-button-white', // Add your class name for next button
				prevEl: '.swiper-button-prev.swiper-button-white' // Add your class name for prev button
			},
			autoplay: {
				delay: 8000,
			},
			spaceBetween: 0,
			loop: this.state.bannerImage.length > 1 ? true : false,
			lazy: true,
			preloadImages: false,
			watchOverflow: true,
		}

		return (
			<div className="suppliers-single-page category_wrapper find_suppliers">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>
						{this.state.name ? this.state.name : 'Gharbanau'} - A place for your thirst
					</title>
					<meta name="keywords" content="Gharbanau, suppliers" />
					<meta
						name="description"
						content="A place for your thirst"
					/>
					<meta property="og:url" content={window.location.href} />
					<meta property="og:type" content="Supplier Details" />
					<meta property="og:description" content={'A place for your thirst'} />
					<meta property="og:title" content={this.state.name} />
					{
						(this.state.logo_fullpath) ?
							<meta property="og:image" content={this.state.logo_fullpath} />
							: null
					}
				</MetaTags>

				{
					(this.state.bannerImage.length > 0) ?

						<Swiper
							{...params}
							wrapperClass={'SwiperBanner' }
							spaceBetween={0}
							slidesPerView={1}
							onSlideChange={() => console.log('slide change')}
							onSwiper={(swiper) => console.log(swiper)}
						>
							{

								(this.state.bannerImage.length > 0) ?
									this.state.bannerImage.map((data, index) => {
										return (
											<div
												className="banners swiper-slide swiper-lazy"
												// style={{ backgroundImage: `url(${data.BannerImage})` }}
												data-background={data.BannerImage}
												key={index}
											>
											<div className="swiper-lazy-preloader"></div>
												{
													(data.BannerLink) ?
														<a href={data.BannerLink} rel="noopener noreferrer">
															<img
																alt='banner'
																className="swiper-lazy"
																data-src={data.BannerImage}
															/>
															<div className="swiper-lazy-preloader"></div>
														</a>
														:
														<div>
															<img
																alt='banner'
																className="swiper-lazy"
																data-src={data.BannerImage}
															/>
															<div className="swiper-lazy-preloader"></div>
														</div>
												}
											</div>
										);
									})
									: null
							}
						</Swiper>

						: null
				}

				<div className="wrapper wrapper_1200 padding_side_15">
					<div className="row">
						<div className="col-lg-3 col-sm-4 suppliers-contents">
							{/* {
							 this.state.aboutcompany || this.state.whychooseus || this.state.contactus || this.state.pageslists.length > 0 ? */}
								<div className={'show-in-mobile nav-bar nav-bar-single-page'}>
									<a className="link  " href={this.state.url}>{utils.Language('lang_home', this.props.language)}</a>

									{
										(this.state.products) ?
										<a className="link active-link " href={this.state.url + '/products'}>{utils.Language('lang_products', this.props.language)}</a>
										:null
									}		
									{
										(this.state.aboutcompany) ?
											<a className="link" href={this.state.url + '/about-company'}>{utils.Language('lang_AboutCompany', this.props.language)}</a>
											: null
									}
									{
										(this.state.whychooseus) ?
											<a className="link" href={this.state.url + '/why-choose-us'}>{utils.Language('lang_WhyChooseUs', this.props.language)}</a>
											: null
									}
									{
										(this.state.contactus) ?
											<a className="link" href={this.state.url + '/contact-us'}>{utils.Language('lang_contactUs', this.props.language)}</a>
											: null
									}
									{
										(this.state.pageslists.length > 0) ?
											this.state.pageslists.map((data, index) => {
												return (
													<a className="link" href={this.state.url + '/' + data.slug} key={index}>{data.title}</a>
												);
											})
											: null
									}
								</div>
								{/* : null
							} */}
							{
								(isBrowser) ?
								<div className="suppliers-list" style={{ height: 'auto' }}>
								{
									(this.state.logo_fullpath) ?
										<div className="suppliers-top suppliers-logo">
											{(1 === parseInt(this.state.vendor_verified)) ? <span className=" verified small-verified varified"><span className="verified-div"><img src={require('../assets/images/certifications.png')} alt="certificate" className="verified-logo" />{utils.Language('lang_verified_supplier', this.props.language)}</span></span> : null}
										</div>
										:
										<div className="suppliers-top no-logo">
											{(1 === parseInt(this.state.vendor_verified)) ? <span className=" verified small-verified varified"><span className="verified-div"><img src={require('../assets/images/certifications.png')} alt="certificate" className="verified-logo" />{utils.Language('lang_verified_supplier', this.props.language)}</span></span> : null}
										</div>
								}
								<div className="suppliers-company-wrapper">
									{
										(this.state.logo_fullpath) ?
											<div className="imgWrapper">
												<img src={this.state.logo_fullpath} alt="categoryimage" />
											</div>
											:
											null
									}

									<div className="suppliers-company-name">{this.state.name}</div>

									<div className="suppliers-address">
										<span className="core-address">{this.state.address_list}</span>
									</div>
									{/*
										((true === this.state.isUserLogin && this.state.vendor_phone) || (true === this.state.isUserLogin && this.state.vendor_phone_alt)) ?
											<div className="suppliers-phone">
												{(this.state.vendor_phone) ? <div className="soldby-phn"><a className="click-to-call" href={"callto:" + this.state.vendor_phone}><span className="icon-call-answer"></span> {this.state.vendor_phone}</a></div> : null}
												{(this.state.vendor_phone_alt) ? <div className="soldby-phn"><a className="click-to-call" href={"callto:" + this.state.vendor_phone_alt}><span className="icon-call-answer"></span> {this.state.vendor_phone_alt}</a></div> : null}
											</div>
											: null
									*/}
									{/* {
										(this.state.distance) ?
											<div className="suppliers-distance-wrapper">
												<div className="suppliers-distance">
													<a href={
														(this.state.latitude && this.state.longitude) ?
															'https://maps.google.com/?q=' + this.state.name + '&ll=' + this.state.latitude + ', ' + this.state.longitude + '&z=17'
															:
															'https://maps.google.com/?q=' + this.state.name + '&daddr=' + this.state.address_list + '&z=17'
													} target="_blank" rel="noopener noreferrer">
														<span className="icon icon-map-marker-alt"></span>
														{Number.parseFloat(this.state.distance).toFixed(2)} {utils.Language('lang_' + this.state.distanceType, this.props.language)}
													</a>
												</div>

												<div className={'view-on-map'}>
													<a href={
														(this.state.latitude && this.state.longitude) ?
															'https://maps.google.com/?q=' + this.state.name + '&ll=' + this.state.latitude + ', ' + this.state.longitude + '&z=17'
															:
															'https://maps.google.com/?q=' + this.state.name + '&daddr=' + this.state.address_list + '&z=17'
													} target="_blank" rel="noopener noreferrer"><span className="icon-map-marker-alt"></span>{utils.Language('lang_viewOnMap', this.props.language)}</a>
												</div>
											</div>
											:
											null
									} */}
									<div className="star-ratings">
										{
											( this.state.rating ) ?
												<StarRatings
												rating={ parseFloat( this.state.rating ) }
												starRatedColor="#ff6f00"
												starHoverColor="#ff6f00"
												numberOfStars={5}
												name='rating'
												starDimension="24px"
												starSpacing="5px"
												/>
											: null
										}
									</div>
									<div className="socialLink">
										{
											(this.state.facebook) ?
												<a className="socialLink-icon socialLink-icon-facebook" href={this.state.facebook} target="_blank" rel="noopener noreferrer"><span className="icon-facebook-f"></span></a>
												: null
										}
										{
											(this.state.instagram) ?
												<a className="socialLink-icon socialLink-icon-instagram" href={this.state.instagram} target="_blank" rel="noopener noreferrer"><span className="icon-instagram"></span></a>
												: null
										}
										{
											(this.state.twitter) ?
												<a className="socialLink-icon socialLink-icon-twitter" href={this.state.twitter} target="_blank" rel="noopener noreferrer"><span className="icon-twitter"></span></a>
												: null
										}
									</div>
									{
										(this.state.vendor_file && this.state.vendor_file.length > 0) ?
											<div className="dealership-certificate">
												<button className="btn btn-primary" type="button" onClick={() => this.setState({ isOpen: true })}>{utils.Language('lang_DealershipCertificate', this.props.language)}</button>
												{isOpen && (
													<Lightbox
														mainSrc={this.state.vendor_file[photoIndex]}
														nextSrc={this.state.vendor_file[(photoIndex + 1) % this.state.vendor_file.length]}
														prevSrc={this.state.vendor_file[(photoIndex + this.state.vendor_file.length - 1) % this.state.vendor_file.length]}
														onCloseRequest={() => this.setState({ isOpen: false })}
														onMovePrevRequest={() =>
															this.setState({
																photoIndex: (photoIndex + this.state.vendor_file.length - 1) % this.state.vendor_file.length
															})
														}
														onMoveNextRequest={() =>
															this.setState({
																photoIndex: (photoIndex + 1) % this.state.vendor_file.length
															})
														}
													/>
												)}
											</div>
											:
											null
									}
								</div>
								<div className="suppliers-contact-person">
									{(this.state.contact_fullname) ? <div className="soldby_icon soldby-seller"><span className="icon"><span className="icon-user"></span></span>{this.state.contact_fullname}</div> : null}
									<div className="call_list_div">
										<span className="icon"><span className="icon-mobile-alt"></span></span>
										<div className="call_list">
										{(this.state.contact_phone) ? <a className="click-to-call" href={"callto:" + this.state.contact_phone}>{this.state.contact_phone}</a> : null}
										{(this.state.vendor_phone) ? <a className="click-to-call" href={"callto:" + this.state.vendor_phone}>{this.state.vendor_phone}</a> : null}
										{(this.state.vendor_phone_alt) ? <a className="click-to-call" href={"callto:" + this.state.vendor_phone_alt}>{this.state.vendor_phone_alt}</a> : null}
										</div>
									</div>
									
									{(this.state.contact_email) ? <div className="soldby_icon mailing"><a href={"mailto:" + this.state.contact_email} className="soldby_icon-link"><span className="icon icon-mailing"></span><span className="mailing-text">{this.state.contact_email}</span></a></div> : null}
									
								</div>

					
								<div className="suppliers-contact-person call-suppliers-btn">
									<a href={(this.state.contact_email) ? "mailto:" + this.state.contact_email : "mailto:info@omvalley.websearchpro.net" }>
									<button title="Send mail" className="click-to-call email-mobile cta-btn" ><span className="icon"><span className="icon icon-mailing"></span></span> {utils.Language('lang_sendMail', this.props.language)}</button>
									</a>
									<a className="click-to-call" href={(this.state.contact_phone) ? "callto:" + this.state.contact_phone :   (this.state.vendor_phone) ? "callto:" + this.state.vendor_phone :  (this.state.vendor_phone_alt) ? "callto:" + this.state.vendor_phone_alt : "callto:+977-01-4785677"   }>
									<button title="Call Gharbanau" className="click-to-call call-mobile cta-btn" ><span className="icon"><span className="icon icon-mobile-alt"></span></span> {utils.Language('lang_callNow', this.props.language)}</button>
									</a>
								</div>

								<div className="socialShareWrap">
									<span className="social-text">
										{utils.Language('lang_socialShare', this.props.language)}
									</span>
									<EmailShareButton url={this.state.url}>
										<EmailIcon size={20} round={false} />
									</EmailShareButton>
									<FacebookShareButton url={this.state.url}>
										<FacebookIcon size={20} round={false} />
									</FacebookShareButton>
									<TwitterShareButton url={this.state.url}>
										<TwitterIcon size={20} round={false} />
									</TwitterShareButton>
									{/* <PinterestShareButton url={this.state.url}>
										<PinterestIcon size={20} round={false} />
									</PinterestShareButton> */}
								</div>
							</div>
								:null
							}				
							{
								(this.state.categoryApi) ?
									<div className="categorywrap" ref={this.myRef}>
										<div className="miniTitle-div">
											<h3 className="miniTitle">{utils.Language('lang_productCategories', this.props.language)}</h3>
											<div className="filter show-in-mobile" onClick={()=>{this.setState({shows:!this.state.shows})}}><span className={ this.state.shows? 'fa fa-filter open' : 'fa fa-filter'}></span> </div>
										</div>

										{
											this.state.shows?
										
										<div className="sidebar-catlist">
											<ul>
												{
													this.state.getCategoriesDropdown.map((item, index) => {
														return (
															<li className={this.state.active && item.id === this.state.categoryid ? 'active test' : null} id={item.id} key={index} onClick={e => this.filterCategories(item.id)}>{true === item.is_child ? <span style={{ paddingLeft: 10 }}> - </span> : null}{item.name}</li>
														)
													})
												}
											</ul>
										</div>

										:null
										}

										<div className="sidebar-catlist show-in-desktop">
											<ul>
												{
													this.state.getCategoriesDropdown.map((item, index) => {
														return (
															<li className={this.state.active && item.id === this.state.categoryid ? 'active' : null} id={item.id} key={index} onClick={e => this.filterCategories(item.id)}>{true === item.is_child ? <span style={{ paddingLeft: 10 }}> - </span> : null}{item.name}</li>
														)
													})
												}
											</ul>
										</div>
									</div>
								: null
							}							
						</div>

						<div className="col-lg-9 col-sm-8">

							{
								this.state.aboutcompany || this.state.whychooseus || this.state.contactus || this.state.pageslists.length > 0 ?
									<div className={'nav-bar nav-bar-single-page show-in-desktop'}>
										{/* <a className="link active-link" href={this.state.url}>{utils.Language('lang_home', this.props.language)}</a> */}
										{
											(this.state.products) ?
												<a className="link" href={this.state.url + '/products'}>{utils.Language('lang_products', this.props.language)}</a>
												: null
										}
										{
											(this.state.aboutcompany) ?
												<a className="link" href={this.state.url + '/about-company'}>{utils.Language('lang_AboutCompany', this.props.language)}</a>
												: null
										}
										{
											(this.state.whychooseus) ?
												<a className="link" href={this.state.url + '/why-choose-us'}>{utils.Language('lang_WhyChooseUs', this.props.language)}</a>
												: null
										}
										{
											(this.state.contactus) ?
												<a className="link" href={this.state.url + '/contact-us'}>{utils.Language('lang_contactUs', this.props.language)}</a>
												: null
										}
										{
											(this.state.pageslists.length > 0) ?
												this.state.pageslists.map((data, index) => {
													return (
														<a className="link" href={this.state.url + '/' + data.slug} key={index}>{data.title}</a>
													);
												})
												: null
										}
									</div>

									: null
							}
							<div className="category_wrapper-list">
								{
									(!this.state.initDoneLoader) ?
										<div className="row">
											{
												(this.state.getSellerProducts && this.state.getSellerProducts.length > 0) ?
													this.state.getSellerProducts.map((product, index) => {
														return (
															<div className="col-lg-3 col-sm-4 col-6" key={product.Item_Id}>
																<div className="categoryList" id={product.Item_Id}>
																	{
																		(product.is_verified === '1' || product.is_verified === 1) ?
																			<div className="verified small-verified"><div className="verified-div"><img src={require('../assets/images/certifications.png')} alt="reward" className="verified-logo" /> {utils.Language('lang_verified_supplier', this.props.language)}</div></div>
																			: null
																	}
																	{
																		('seller' !== this.props.userType) ?
																			<div className="wishlist-div">
																				{
																					(true === product.wishlistStatus) ?
																						<div className="wishlist wishlist-added" title="Wishlist" onClick={() => { this.removeFromWishlist(product.Item_Id) }}><span className="icon-heart-black icon-heart-red icon-fav"></span></div>
																						:
																						<div className="wishlist" title="Wishlist" onClick={() => { this.addToWishlist(product.Item_Id) }}><span className="icon-heart-black icon-fav"></span></div>
																				}
																			</div>
																			: null
																	}
																	<Link to={'/products/' + encodeURIComponent(product.slug)} className="link">
																		<div className="imgWrapper" style={{ }}>
																			<img src={product.imageUrl ? product.imageUrl : noImage} alt="categoryimage" />
																		</div>
																		<span className="product-name">{product.name}</span>
																		{
																			(product.distance) ?
																				<div className="product-distance">
																					<span className="icon icon-map-marker-alt"></span>
																					{product.distance} {utils.Language('lang_' + product.distanceType, this.props.language)}</div>
																				:
																				null
																		}
																		{
																			(product.company_name || product.addressList) ?
																				<div className="supplierdetails">
																					<span className="company">{product.company_name}</span>
																					<span className="address">{product.addressList}</span>
																				</div>
																				: null
																		}
																		<div className="priceSec">
																			{
																				(product.regularPrice !== product.salePrice && product.salePrice !== '0.00') ?
																					<div><span className="regularprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.regularPrice)}</span>
																						<span className="saleprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.salePrice)}
																							{
																								(product.salePriceValidTill) ?
																									<div className="singleBrands validtilldate">
																										<span className="smallText">({utils.Language('lang_priceValidTill', this.props.language)} {product.salePriceValidTill})</span>
																									</div>
																									: null
																							}
																						</span></div>
																					:
																					<span className="saleprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.regularPrice)}</span>
																			}
																		</div>
																	</Link>

																	<div className="starrating-div">
																	{
																		( product.rating ) ?
																			<StarRatings
																			rating={ parseFloat( product.rating ) }
																			starRatedColor="#ff6f00"
																			starHoverColor="#ff6f00"
																			numberOfStars={5}
																			name='rating'
																			starDimension="20px"
																			starSpacing="0"
																			/>
																		: null
																	}
																	</div>
																	{
																		('seller' !== this.props.userType) ?
																			<div data-mh className="productDetail productDetailnew">
																				<button type="button" className="btn btn-danger btn-add-cart" onClick={() => { this.addItemToCart(product.Item_Id, parseInt(product.minimum_quantity)) }}><span className="icon-cart-add"></span>{utils.Language('lang_addToCart', this.props.language)}</button>
																			</div>
																			: null
																	}
																</div>
															</div>
														)
													})
													:
													<div className="col-lg-12 col-sm-12 col-12 text-center">
														<div className="suppliers-no-products pad-lft-15">
															<h5>{utils.Language('lang_supplierProductNotFound', this.props.language)}</h5>
														</div>

														<NewProduct />
													</div>
											}
										</div>
										: <div className="row">{this.renderSuppliers()}</div>
								}
								<div className="pagination_wrapper">
									{
										(0 !== parseFloat(this.state.totalProducts)) ?
											<span className="pagination-items">{this.state.totalProducts} {utils.Language('lang_itemFound', this.props.language)}</span>
											: null
									}
									{
										(this.state.totalProducts > this.state.limit && 'list' === this.state.paginationtype) ?
											<Pagination
												totalRecords={parseInt(this.state.totalProducts)}
												pageLimit={parseInt(this.state.limit)}
												pageNeighbours={1}
												onPageChanged={this.onPageChanged}
											/>
											: null
									}
									{
										(this.state.totalProducts > this.state.limit && 'filter' === this.state.paginationtype) ?
											<Pagination
												totalRecords={parseInt(this.state.totalProducts)}
												pageLimit={parseInt(this.state.limit)}
												pageNeighbours={1}
												onPageChanged={this.onPageChanged}
											/>
											: null
									}
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: (count) => dispatch(setCartCount(count)),
	setAlertMessage: (message) => dispatch(setAlertMessage(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSupplier);