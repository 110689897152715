// Show category list
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';
import noImage from '../../assets/images/no-image-available.png';
import { isBrowser } from 'react-device-detect';

// Language
import utils from '../../common/utils';

// Redux set data
import { connect } from "react-redux";

// Lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';

class CategoryList extends Component {
	constructor() {
		super()
		this.state = {
			CategoriesApi: false,
			limit: 12
		}
	}

	// fetch all categories from api
	async componentDidMount() {
		let params = {
			ViewType : 'weblist',
			limit : this.state.limit,
			lang  : this.props.language,
			vendorID: this.props.vendorInfos.data.id
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let featured = await webservice.httpPost( Api.method.getAllCategories, params );
		if ( featured.status === 'ok' ) {
			this.setState({
				categories: featured.data,
				CategoriesApi: true,
			})
		}
	}

	renderFeatured() {
		const featured = [];
		for (let i = 0; i < 12; i++) {
			// Try avoiding the use of index as a key, it has to be unique!
			featured.push(
				<div className="categoryList" key={i}>
					<div className="imgWrapper" style={{boxShadow: 'none', alignItems: 'center', flexDirection: 'column',}}>
						<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px',}}>
							<img src={ require('../../assets/images/placeholder.png') } alt="productsimage" style={{width: '40px'}} />
						</div>
						<div style={{width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px',alignItems: 'center',}}></div>
					</div>
				</div>
			);
		}
		return featured;
	}

	render() {
		return (
			<section className="section category_wrapper">
				<div className="wrapper wrapper_1200">
					<h2 className="catTitle"><Link to={'/product-categories'} className="link">{ utils.Language( 'lang_categories', this.props.language ) }</Link></h2>
					<div className="row">
						<div className="col-12">
							
							<div className="d-flex sidemargin">
							{
								( this.state.CategoriesApi ) ?
									this.state.categories.map( (category,index) => {
										// Check image url
										var imageUrl = noImage;
										if ( category.imageUrl ) {
											imageUrl = category.imageUrl;
										} else if ( category.fullimageUrl ) {
											imageUrl = category.fullimageUrl;
										}

										return(
											<div className="categoryList" key={category.id}>
											
												<Link to={'/category/'+encodeURIComponent( category.slug )} className="link">
													<div className="imgWrapper">
														<LazyLoadImage src={ 'https://cdn.shortpixel.ai/client/q_glossy,ret_img/'+imageUrl } alt={category.name} />
													</div>
													<span>{category.name}</span>
												</Link>
											</div>
										)
									})
								:
								this.renderFeatured()
							}
							</div>
						</div>
					</div>
				</div>
				{/*<div className="btnWrapper">
					<Link to="/product-categories" className="btn btn-outline btn-outline-default btn-radius-20 btn-small-curve">{ utils.Language( 'lang_viewAll', this.props.language ) }</Link>
				</div>*/}
			</section>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(CategoryList);