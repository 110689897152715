// Cart js
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import noImage from '../assets/images/no-image-available.png';
import MetaTags from 'react-meta-tags';
import CartList from '../components/cart/CartList';
import Popup from "reactjs-popup";
import AddressDetailsEdit from '../pages/userpages/AddressDetailsEdit';
import Loader from '../common/Loader';

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";
import { setCartCount, setAlertMessage } from '../action/commonAction';

class Cart extends Component {
	constructor() {
		super()
		this.state = {
			cartItemsApi : false,
			updateDisable : true,
			qtychanges : false,
			totalAmount: 0,
			shippingAmount: 0,
			noOfCartItem: 0,
			grandTotal: 0,
			getDistricts: [],
			billingAddressStatus: false,
			maxQty: 999999,
			pointerEvent: false,
			billingAddress: {
				Full_Name        : null,
				Address          : null,
				Region           : null,
				City             : null,
				Mobile_Number    : null,
				Nearest_Landmark : null
			},
			shippingAddress: {
				Full_Name        : null,
				Address          : null,
				Region           : null,
				City             : null,
				Mobile_Number    : null,
				Nearest_Landmark : null
			},
			updateIds : []
		}
	}

	async componentDidMount() {
		const userdetails = localStorage.getItem( 'userdetail' );
		if ( ! userdetails ) {
			window.location.href = "/";
			return false;
		}

		let params = {
			lang : this.props.language
		}

		let getCartItem = await webservice.httpPost( Api.method.cartItems, params );
		let getUserAddress = await webservice.httpPost( Api.method.getUserAddress );
		let getRegion = await webservice.httpPost( Api.method.getRegion, params );

		if ( getRegion.status === 'ok' ) {
			this.setState({
				getDistricts : getRegion.data
			})
		}

		if ( getCartItem.status === 'ok' ) {
			this.setState({
				cartItemsApi : true,
				cartItems    : getCartItem.data
			})

			const cartItems = getCartItem.data ? getCartItem.data : []

			let totalAmount = 0;
			let shippingAmount = 0;

			cartItems.map((item, index) => {
				totalAmount += parseFloat(item.price, 2) * item.quantity;
				shippingAmount += parseFloat( item.shipping_price, 2 );
				return shippingAmount;
			});

			var grandTotal = totalAmount + shippingAmount;

			this.setState({
				totalAmount: new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(totalAmount),
				shippingAmount: new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(shippingAmount),
				grandTotal: new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(grandTotal),
				noOfCartItem: cartItems.length
			});
		}

		if ( getUserAddress.status === 'ok' ) {
			this.setState({
				cartItemsApi   : true,
				getUserAddress : getUserAddress.data
			});
			if ( ! getUserAddress.data.Billing_Address ) {
				const userdetail = localStorage.getItem( 'userdetail' );
				if ( userdetail ) {
					const details = JSON.parse( userdetail );
					this.setState({
						getUserAddress: {
							Billing_Address: {
								Full_Name        : details.FullName,
								Mobile_Number    : details.Mobile_Number
							}
						}
					});
				}
				this.openModalPopup();
				return false;
			}

			if ( getUserAddress.data.Billing_Address ) {

				if ( getUserAddress.data.Billing_Address.Full_Name && getUserAddress.data.Billing_Address.Mobile_Number && getUserAddress.data.Billing_Address.Address && getUserAddress.data.Billing_Address.City && getUserAddress.data.Billing_Address.Region  ) {
					this.setState({
						billingAddressStatus : true
					})
				}

				this.setState({
					billingAddress: {
						Full_Name        : getUserAddress.data.Billing_Address.Full_Name,
						Address          : getUserAddress.data.Billing_Address.Address,
						Region           : getUserAddress.data.BillingRegion,
						City             : getUserAddress.data.Billing_Address.City,
						Mobile_Number    : getUserAddress.data.Billing_Address.Mobile_Number,
						Nearest_Landmark : getUserAddress.data.Billing_Address.Nearest_Landmark
					}
				});
			}

			if ( getUserAddress.data.Shipping_Address ) {
				this.setState({
					shippingAddress: {
						Full_Name        : getUserAddress.data.Shipping_Address.Full_Name,
						Address          : getUserAddress.data.Shipping_Address.Address,
						Region           : getUserAddress.data.ShippingRegion,
						City             : getUserAddress.data.Shipping_Address.City,
						Mobile_Number    : getUserAddress.data.Shipping_Address.Mobile_Number,
						Nearest_Landmark : getUserAddress.data.Shipping_Address.Nearest_Landmark
					}
				});
			}
		}
	}

	// removeFromCart
	removeFromCart = async (id) => {
		const params = {
			ProductID: id
		}

		const deleteSucceed = await webservice.httpPost( Api.method.removeCartItem, params );
		if ( 'ok' === deleteSucceed.status ) {
			const index = this.state.cartItems.findIndex(p => p.id === id );
			this.props.setCartCount( deleteSucceed.data.count );
			this.props.setAlertMessage( utils.Language( 'lang_deleteSuccess', this.props.language ) );

			if ( 0 === deleteSucceed.data.count ) {
				this.setState({
					cartItems   : null,
					cartItemsApi : true
				});
				return false;
			}

			if ( index >= 0 ) {
				this.state.cartItems.splice( index, 1 );
				this.changeDataafterUpdateRemove();
			}
		}
	}

	// quantity change
	onQtyChange = async ( e, id ) => {
		let { value, min, max } = e.target;
		value = Math.max( Number( min ), Math.min( Number( max ), Number( value ) ) );

		this.updateCartQtyOnChanges( value, id );
	}

	// Quantity on click plus sign store it and quantity
	stepQtyIncrement = ( value, id, max ) => {
		if ( parseInt( value ) !== parseInt( max ) ) {
			var incr = parseInt( value ) + 1;
			this.updateCartQtyOnChanges( incr, id );
		}
		// document.getElementById( 'qtyUpdate' + id ).stepUp();
		// const value = document.getElementById( 'qtyUpdate' + id  ).value;

		// this.updateCartQtyOnChanges( value, id );
	}

	// Quantity on click minus sign store it and quantity
	stepQtyDecrement = ( value, id, min ) => {
		if ( parseInt( value ) !== parseInt( min ) ) {
			var dec = parseInt( value ) - 1;
			this.updateCartQtyOnChanges( dec, id );
		}
		// document.getElementById( 'qtyUpdate' + id ).stepDown();
		// const value = document.getElementById( 'qtyUpdate' + id  ).value;

		// this.updateCartQtyOnChanges( value, id );
	}

	// Store quantity and id on plus, minus click and change on quantity
	updateCartQtyOnChanges = ( value, id ) => {
		if ( value && id ) {
			this.setState({
				pointerEvent : true
			});

			let param = {
				'updateIds' : [{ id: id, quantity: value }]
			}

			this.updateCartItem( param );
		}

		const index = this.state.cartItems.findIndex( p => p.id === id );
		let newArray = [...this.state.cartItems];
		newArray[index]['quantity'] = value;

		this.setState({
			cartItems   : newArray,
			cartItemsApi : true,
			updateDisable : false,
			qtychanges : true
		});

		// store updated quantity as per product ids for update
		const indexid = this.state.updateIds.findIndex( p => p.id === id );
		if ( indexid === -1 ) {
			this.setState({
				updateIds: [ ...this.state.updateIds, { id: id, quantity: value }]
			});
		} else {
			let newupdateArray = [...this.state.updateIds];
			newupdateArray[indexid]['quantity'] = value;
		}
	}

	// remove cart items by id
	onRemoveClick = (e, id) => {
		this.removeFromCart( id );
	}

	// update cart quantity per ids
	updateCartItem = async ( param ) => {
		const updateCart = await webservice.httpPost( Api.method.updateCartItem, param );

		if ( 'ok' === updateCart.status ) {
			this.setState({
				pointerEvent : false
			});

			this.changeDataafterUpdateRemove();
			this.props.setAlertMessage( utils.Language( 'lang_updateSuccess', this.props.language ) );
		}
	}

	// Confirm order
	confirmOrder = async () => {
		if ( ! this.state.billingAddressStatus ) {
			this.props.setAlertMessage( utils.Language( 'lang_NoAddress', this.props.language ) );
		} 

		if ( this.state.billingAddressStatus ) {
			document.location.href = '/thankyou';
		}
	}

	changeDataafterUpdateRemove = () => {
		let getCartItem = this.state.cartItems;

		if ( getCartItem ) {

			let totalAmount = 0;
			let shippingAmount = 0;

			getCartItem.map((item, index) => {
				totalAmount += parseFloat(item.price, 2) * item.quantity;
				shippingAmount += parseFloat( item.shipping_price, 2 );
				return shippingAmount;
			});

			var grandTotal = totalAmount + shippingAmount;

			this.setState({
				cartItemsApi : true,
				noOfCartItem: getCartItem.length,
				grandTotal: new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(grandTotal),
				totalAmount: new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(totalAmount),
				shippingAmount: new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(shippingAmount)
			});
		}
	}

	openModalPopup = (e) =>  {
		this.setState({ open: true });
		document.getElementById( "body" ).classList.add("background_fixed");
	}

	closeModalPopup = (e) => {
		this.setState({ open: false });
		document.getElementById( "body" ).classList.remove("background_fixed");
	}

	changeDeatilsOnUpdate = async ( e ) => {
		let getUserAddress = await webservice.httpPost( Api.method.getUserAddress );

		if ( getUserAddress.status === 'ok' && getUserAddress.data.Billing_Address ) {
			if ( getUserAddress.data.Billing_Address.Full_Name && getUserAddress.data.Billing_Address.Mobile_Number && getUserAddress.data.Billing_Address.Address && getUserAddress.data.Billing_Address.City && getUserAddress.data.Billing_Address.Region  ) {
				this.setState({
					billingAddressStatus : true
				})
			}

			this.setState({
				getUserAddress   : getUserAddress.data,
				cartItemsApi     : true,
				billingAddress: {
					Full_Name        : getUserAddress.data.Billing_Address.Full_Name,
					Address          : getUserAddress.data.Billing_Address.Address,
					Region           : getUserAddress.data.BillingRegion,
					City             : getUserAddress.data.Billing_Address.City,
					Mobile_Number    : getUserAddress.data.Billing_Address.Mobile_Number,
					Nearest_Landmark : getUserAddress.data.Billing_Address.Nearest_Landmark
				},
				shippingAddress: {
					Full_Name        : getUserAddress.data.Shipping_Address.Full_Name,
					Address          : getUserAddress.data.Shipping_Address.Address,
					Region           : getUserAddress.data.ShippingRegion,
					City             : getUserAddress.data.Shipping_Address.City,
					Mobile_Number    : getUserAddress.data.Shipping_Address.Mobile_Number,
					Nearest_Landmark : getUserAddress.data.Shipping_Address.Nearest_Landmark
				}
			});
		}
	}

	render() {
		return (
			<div className="cartPage bgGray">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>Cart - A place for your thirst</title>
					<meta name="keywords" content="cart, gharbanau" />
					<meta
					name="description"
					content="A place for your thirst"
					/>
				</MetaTags>
				{
					( this.state.cartItemsApi ) ?
					<div className="wrapper wrapper_1200">
					{ ( this.state.cartItems ) ?
						<div className="row">
							<div className="col-sm-8 col-lg-8 col-12">
								<div className="cart-items cart-header">
									<div className="cartItems">{ utils.Language( 'lang_orderItems', this.props.language ) }</div>
									<div className="cartPrice">{ utils.Language( 'lang_price', this.props.language ) } ( { utils.Language( 'lang_currency', this.props.language ) } )</div>
									<div className="cartQty">{ utils.Language( 'lang_qty', this.props.language ) }</div>
									<div className="cartAmnt">{ utils.Language( 'lang_Total', this.props.language ) } ( { utils.Language( 'lang_currency', this.props.language ) } )</div>
								</div>
								<div className={ this.state.pointerEvent ? 'opacity userlistWrapper' : 'userlistWrapper' }>
								{
									( this.state.cartItems ) ?
										this.state.cartItems.map( ( item, index ) => {
											var min = '';

											if ( 0 === parseInt( item.minimum_quantity ) ) {
												min = 1;
											} else {
												min = item.minimum_quantity;
											}
											return(
												<CartList
												id = { item.id }
												key = { item.id }
												name = { item.name }
												slug = { item.slug }
												price = { item.price }
												count = { item.count }
												minimum_quantity = { min }
												maximum_quantity = { this.state.maxQty }
												pointerEvent = { this.state.pointerEvent }
												quantity = { item.quantity }
												vendorName = { item.vendorName }
												language = { this.props.language }
												shipping_type = { item.shipping_type }
												shippingprice = { item.shipping_price }
												img = { item.imageUrl ? item.imageUrl : noImage }
												onQtyChange = { (e, id) => this.onQtyChange( e, id ) }
												onRemoveClick = { (e, id) => this.onRemoveClick( e, id ) }
												stepQtyIncrement = { (e, id, max) => this.stepQtyIncrement( e, id, max ) }
												stepQtyDecrement = { (e, id, min) => this.stepQtyDecrement( e, id, min ) }
												/>
											)
										})
									: null
								}
								</div>
								{
									/*<div className="btnWrapper cartUpdate">
										<button onClick={ this.updateCartItem } className="btn btn-success" disabled={ this.state.updateDisable }>{ utils.Language( 'lang_update', this.props.language ) }</button>
									</div>*/
								}
							</div>
							<div className="col-sm-4  col-lg-4 col-12">
								<div className="cart-order">
									<div className="cart-placeorder">
										<div className="cart-placeorder-btn">
											<button onClick={ this.confirmOrder } className="btn placeorder-text">{ utils.Language( 'lang_confirmOrder', this.props.language ) }</button>
											{/*<Link to="/thankyou" className="placeorder-text">Confirm Order</Link>*/}
										</div>
									</div>

									<div className="cart-shipping">
										<span className="shipping-title font-16reg">{ utils.Language( 'lang_billingShippingAddress', this.props.language ) }</span>

										<div className="shipping_location">
											<div className="shipping_address">
												<span className="icon icon-maps-and-flags"></span>
												<span className="shipping_text">
													<p>
														{ utils.Language( 'lang_billingAddress', this.props.language ) }
														<br />
														{
															( this.state.billingAddress.Full_Name ) ?
															<span>{ this.state.billingAddress.Full_Name }<br /></span>
															: null
														}
														{
														( this.state.billingAddress.Address ) ?
															<span>{ this.state.billingAddress.Address }<br /></span>
															: null
														}
														{
														( this.state.billingAddress.Region ) ?
															this.state.billingAddress.Region
															: null
														}
														{
														( this.state.billingAddress.City ) ?
															<span>, { this.state.billingAddress.City }<br /></span>
															: null
														}
														{
														( this.state.billingAddress.Mobile_Number ) ?
															this.state.billingAddress.Mobile_Number
															: null
														}
													</p>
												</span>
											</div>

											<div className="shipping_edit">
												<div className="edit-details-wrap">
													<button className="button" onClick={this.openModalPopup}>
													<span className="icon-pencil-striped-symbol-for-interface-edit-buttons"></span>
													</button>													
												</div>
											</div>
										</div>

										<div className="shipping_bill">
											<div className="shipping_address">
												<span className="icon icon-file-alt"></span>
												<span className="shipping_text">
													<p>
														{ utils.Language( 'lang_shippingAddress', this.props.language ) }
														<br />
														{
															( this.state.shippingAddress.Full_Name ) ?
															<span>{ this.state.shippingAddress.Full_Name }<br /></span>
															: null
														}
														{
														( this.state.shippingAddress.Address ) ?
															<span>{ this.state.shippingAddress.Address }<br /></span>
															: null
														}
														{
														( this.state.shippingAddress.Region ) ?
															this.state.shippingAddress.Region
															: null
														}
														{
														( this.state.shippingAddress.City ) ?
															<span>, { this.state.shippingAddress.City }<br /></span>
															: null
														}
														{
														( this.state.shippingAddress.Mobile_Number ) ?
															this.state.shippingAddress.Mobile_Number
															: null
														}
													</p>
												</span>
											</div>
										</div>

										{/*<div className="shipping_tell">
											<div className="shipping_address">
												<span className="icon icon-call-answer"></span>
												<span className="shipping_text">
													<p>{ this.state.Mobile_Number }</p>
												</span>
											</div>

											<div className="shipping_edit">
												<span className="icon-pencil-striped-symbol-for-interface-edit-buttons"></span>
											</div>
										</div>

										<div className="shipping_mail">
											<div className="shipping_address">
												<span className="icon icon-mailing"></span>
												<span className="shipping_text">
													<p>infosamdong@gmail.com</p>
												</span>
											</div>
											<div className="shipping_edit">
												<span className="icon-pencil-striped-symbol-for-interface-edit-buttons"></span>
											</div>
										</div>*/}

										<div className="order-summary">
											<span className="shipping-title font-16reg">{ utils.Language( 'lang_OrderSummaryLabel', this.props.language ) }</span>

											<div className="order-subtotal order-summary-div">
												<span className="subtotal-text">{ utils.Language( 'lang_subTotal', this.props.language ) } ({ ( this.state.noOfCartItem > 1 ) ? this.state.noOfCartItem + ' ' + utils.Language( 'lang_orderItems', this.props.language ) : this.state.noOfCartItem + utils.Language( 'lang_orderItems', this.props.language ) })</span>
												<span className="subtotal-price">{ utils.Language( 'lang_currency', this.props.language ) } { this.state.totalAmount }</span>
											</div>
											{
												( '0' !== this.state.shippingAmount || 0 !== parseFloat( this.state.shippingAmount ) ) ?
													<div className="order-fee order-summary-div">
														<span className="fee-text">{ utils.Language( 'lang_shippingFee', this.props.language ) }</span>
														<span className="fee-price">{ utils.Language( 'lang_currency', this.props.language ) } { this.state.shippingAmount }</span>
													</div>
												: null
											}
											<div className="order-total order-summary-div">
												<span className="total-text">{ utils.Language( 'lang_Total', this.props.language ) }</span>
												<span className="total-price">{ utils.Language( 'lang_currency', this.props.language ) } { this.state.grandTotal }</span>
											</div>
											<div className="price text-right">
												<span className="smallText">{ utils.Language( 'lang_taxText', this.props.language ) }</span>
											</div>
										</div>
									</div>

									<div className="cart-placeorder">
										<div className="order-payment">
											<div className="order-payment-cash">
												<div className="imageWrapper">
													<img src={require('../assets/images/cash@2x.png')} alt="cartimage" />
												</div>
												<span className="cashondelivery">{ utils.Language( 'lang_cashOnDelivery', this.props.language ) }</span>
											</div>
											<p>{ utils.Language( 'lang_cashOnDeliveryTextCart', this.props.language ) }</p>
										</div>
										<div className="cart-placeorder-btn">
											<button onClick={ this.confirmOrder } className="btn placeorder-text">{ utils.Language( 'lang_confirmOrder', this.props.language ) }</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					: <p className="emptyData">{ utils.Language( 'lang_CartEmpty', this.props.language ) } <Link to="/" className="linkText-black btn">{ utils.Language( 'lang_ContinueShoppingLabel', this.props.language ) }</Link></p>
					}
					</div>
					: <Loader />
				}

				<Popup
				open={this.state.open}
				closeOnDocumentClick
				onClose={this.closeModalPopup}
				>
					<div className="PopupBox">
						<div className="modalHeader">
							<button className="close" onClick={this.closeModalPopup}>&times;</button>
							<p>{ utils.Language( 'lang_billingShippingAddress', this.props.language ) }</p>
						</div>
						<div className="modalBody">
							<AddressDetailsEdit
							addressDetails={ this.state.getUserAddress }
							onUpdate={ this.changeDeatilsOnUpdate }
							closeModal={ this.closeModalPopup }
							language={ this.props.language }
							districts={ this.state.getDistricts }
							/>
						</div>
					</div>
				</Popup>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: ( count ) => dispatch( setCartCount( count ) ),
	setAlertMessage: ( message ) => dispatch( setAlertMessage( message ) )
});

export default connect( mapStateToProps, mapDispatchToProps )(Cart);