// RelatedProducts js
import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import '../../assets/styles/_swiper.scss';
import noImage from '../../assets/images/no-image-available.png';

// Language
import utils from '../../common/utils';

const ManipulatingrelatedProductSwiper = ( relatedproducts ) => {
	const [swiper, updateSwiper] = useState(null);

	const goNext = () => {
		if (swiper !== null) {
			swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (swiper !== null) {
			swiper.slidePrev();
		}
	};

	const params = {
		slidesPerView : 2,
		loop          : ( relatedproducts.data.length >= 6 ) ? true : false,
		shouldSwiperUpdate: true,
        breakpoints: {
        	1200: {
                slidesPerView: 6,
        	},
        	992: {
                slidesPerView: 4,
        	},
            575: {
                slidesPerView: 3,
            },
            260: {
                slidesPerView: 2,
            },
        },
		// spaceBetween: 30,
		// pagination: {
			// el: '.swiper-pagination',
			// clickable: true,
		// }
	}

return (
	<div className="category_wrapper-list relatedProduct featureProduct_wrapper related-product">
		<div className="wrapper wrapper_1200 padding_side_15">
			<h3 className="catTitle title-withline"><span className="wrapwhite">{ utils.Language( 'lang_youMayLike', relatedproducts.language ) }</span></h3>
			<div className="por">
				<Swiper className="row" {...params} getSwiper={updateSwiper}>
				{
					relatedproducts.data.map( (products, index) => {
						return(
							<div className="categoryList featuredProducts" key={products.id} id={products.id}>
								<div className="boxed">
									{
										( products.is_verified === '1' || products.is_verified === 1 ) ?
											<div className="verified small-verified"><div className="verified-div"><img src={require('../../assets/images/certifications.png')} alt="reward" className="verified-logo" /> { utils.Language( 'lang_verified', relatedproducts.language ) }</div></div>
										: null
									}
									{
										( 'seller' !== relatedproducts.userType ) ?
										<div className="wishlist-div">
											{
												( true === products.wishlistStatus ) ?
												<div className="wishlist wishlist-added" title="Wishlist" onClick={ () => { relatedproducts.removeFromWishlist( products.id, 'relatedProducts' ) } }><span className="icon-heart-black icon-heart-red icon-fav"></span></div>
												:
												<div className="wishlist" title="Wishlist" onClick={() => { relatedproducts.addToWishlist( products.id, 'relatedProducts' ) } }><span className="icon-heart-black icon-fav"></span></div>
											}
										</div>
										: null
									}
									<div className="mostPopularProducts">
										<Link to={'/products/' + encodeURIComponent( products.slug )} onClick={ relatedproducts.refreshData } className="link">
											<div className="imgWrapper">
												<img src={ products.imageUrl ? products.imageUrl : noImage } alt="productsimage" />
											</div>

											<div data-mh className="productDetail">
												<div className="proName">{products.name}</div>												
												{
													( products.company_name || products.addressList ) ?
													<div className="supplierdetails">
														<span className="company">{ products.company_name }</span>
														<span className="address">{ products.addressList }</span>
													</div>
													: null
												}
												{/* {
													( products.distance ) ?
													<div className="proDistance"><span className="icon icon-map-marker-alt"></span> { Number.parseFloat( products.distance ).toFixed(2) } { utils.Language( 'lang_' + products.distanceType, relatedproducts.language ) }</div>
													: null
												} */}
												<div className="priceSec">
												{
													( products.regularPrice !== products.salePrice && products.salePrice !== '0.00'  ) ?
														<div><span className="regularprice">{ utils.Language( 'lang_currency', relatedproducts.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
														<span className="saleprice">{ utils.Language( 'lang_currency', relatedproducts.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.salePrice ) }
														{
															( products.salePriceValidTill ) ?
																<div className="singleBrands validtilldate">
																	<span className="smallText">({ utils.Language( 'lang_priceValidTill', relatedproducts.language ) } { products.salePriceValidTill })</span>
																</div>
															: null
														}
														</span></div>
													:
													<span className="saleprice">{ utils.Language( 'lang_currency', relatedproducts.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
												}
												</div>
											</div>
										</Link>
										<div className="starrating-div">
										{
											( products.rating ) ?
												<StarRatings
												rating={ parseFloat( products.rating ) }
												starRatedColor="#ff6f00"
												starHoverColor="#ff6f00"
												numberOfStars={5}
												name='rating'
												starDimension="20px"
												starSpacing="0"
												/>
											: null
										}
										</div>
										{
											( 'seller' !== relatedproducts.userType ) ?
												<div data-mh className="productDetail productDetailnew">
													<button type="button" className="btn btn-danger btn-add-cart" onClick={() => { relatedproducts.addItemToCart( products.id, parseInt( products.minimum_quantity ) ) }}><span className="icon-cart-add"></span>{ utils.Language( 'lang_addToCart', relatedproducts.language ) }</button>
												</div>
											: null
										}
									</div>
								</div>
							</div>
						)
					})
				}
				</Swiper>
				<button onClick={goPrev} className="prev"><span className="icon-back"></span></button>
				<button onClick={goNext} className="next"><span className="icon-next"></span></button>
			</div>
		</div>
	</div>
	);
};

export default ManipulatingrelatedProductSwiper;