// FAQs js
import React, {Component} from 'react';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import noImage from '../assets/images/no-image-available.png';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';

// Redux set data
import { connect } from "react-redux";

// Language
import utils from '../common/utils';

// Lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';

class FAQs extends Component {
	constructor() {
		super()
		this.state = {
			featuredProductsApi : false,
			pageDetailApi : false,
			pageTitle : '',
		}
	}

	async componentDidMount() {
		let params = {
			pageSlug : 'faq',
			lang     : this.props.language
		}

		let getPageDetail = await webservice.httpPost( Api.method.getLatestProducts, params );
		if ( getPageDetail.status === 'ok' ) {
			this.setState({
				pageDetailApi    : true,
				pageTitle        : getPageDetail.title,
				latestProducts   : getPageDetail.data,
			})
		}
	}

	render() {
		return (
			<section id="LatestProducts" className="section home-product_wrapper bgWhite">
				<div className="wrapper wrapper_1200">
					<div className="featureProduct_wrapper">
						<h2 className="catTitle">{ this.state.pageTitle }</h2>
						<div className="row">
							{
								( this.state.pageDetailApi ) ?
									this.state.latestProducts.map( (products,index) => {
										return(
										<div className="col-lg-2 col-md-3 col-sm-4 col-6" key={products.id}>
											<div className="featuredProducts" id={ products.id }>
												{
													( products.is_verified === '1' || products.is_verified === 1 ) ?
														<div className="verified small-verified"><div className="verified-div"><img src={require('../assets/images/certifications.png')} alt="reward" className="verified-logo" /> { utils.Language( 'lang_verified', this.props.language ) }</div></div>
													: null
												}
												{
													( 'seller' !== this.props.userType ) ?
													<div className="wishlist-div">
														{
															( true === products.wishlistStatus ) ?
															<div className="wishlist wishlist-added" title="Wishlist" onClick={ () => { this.removeFromWishlist( products.id ) } }><span className="icon-heart-black icon-heart-red icon-fav"></span></div>
															:
															<div className="wishlist" title="Wishlist" onClick={() => { this.addToWishlist( products.id ) } }><span className="icon-heart-black icon-fav"></span></div>
														}
													</div>
													: null
												}
												<Link to={'/products/' + encodeURIComponent( products.slug )} className="link">
													<div data-mh className="imgWrapper">
														<LazyLoadImage src={ products.imageUrl ? products.imageUrl : noImage } alt={ products.name } />
													</div>
													<div data-mh className="productDetail">
														<div className="proName">{products.name}</div>
														{/* {
															( products.distance ) ?
															<div className="proDistance"><span className="icon icon-map-marker-alt"></span> { Number.parseFloat( products.distance ).toFixed(2) } { utils.Language( 'lang_' + products.distanceType, this.props.language ) }</div>
															: null
														} */}

														{
															( products.company_name || products.addressList ) ?
															<div className="supplierdetails">
																<span className="company">{ products.company_name }</span>
																<span className="address">{ products.addressList }</span>
															</div>
															: null
														}
														<div className="priceSec">
														{
															( products.regularPrice !== products.salePrice && products.salePrice !== '0.00'  ) ?
																<div><span className="regularprice">{ utils.Language( 'lang_currency', this.props.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
																<span className="saleprice">
																	{ utils.Language( 'lang_currency', this.props.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.salePrice ) }
																	{
																		( products.salePriceValidTill ) ?
																			<div className="singleBrands validtilldate">
																				<span className="smallText">({ utils.Language( 'lang_priceValidTill', this.props.language ) } { products.salePriceValidTill })</span>
																			</div>
																		: null
																	}
																</span></div>
															:
															<span className="saleprice">{ utils.Language( 'lang_currency', this.props.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
														}
														</div>
													</div>
												</Link>
												<div className="starrating-div">
												{
													( products.rating ) ?
														<StarRatings
														rating={ parseFloat( products.rating ) }
														starRatedColor="#ff6f00"
														starHoverColor="#ff6f00"
														numberOfStars={5}
														name='rating'
														starDimension="20px"
														starSpacing="0"
														/>
													: null
												}
												</div>
												{
													( 'seller' !== this.props.userType ) ?
														<div data-mh className="productDetail productDetailnew">
															<button type="button" className="btn btn-danger btn-add-cart" onClick={() => { this.addItemToCart( products.id, parseInt( products.minimum_quantity ) ) }}><span className="icon-cart-add"></span>
															{ utils.Language( 'lang_addToCart', this.props.language ) }</button>
														</div>
													: null
												}
											</div>
										</div>
										)
									})
								: null
							}
						</div>
					</div>
				</div>
			</section>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(FAQs);