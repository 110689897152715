// HowToSell js
import React, {Component} from 'react';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import renderHTML from 'react-render-html';
import MetaTags from 'react-meta-tags';
import Loader from '../common/Loader';
import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon } from "react-share";

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";

class HowToSell extends Component {
	constructor() {
		super()
		this.state = {
			pageDetailApi: false,
			url : ''
		}
	}

	async componentDidMount() {
		var str = Api.backEndapiUrl;
		var strrepl = str.replace(/\/+$/, '');
		this.setState({ url : strrepl + this.props.match.url });

		let params = {
			lang     : this.props.language,
			pageSlug : this.props.match.params.slug,
			vendorID : this.props.vendorInfos.data.id
		}

		let getPageDetail = await webservice.httpPost( Api.method.getPageDetail, params );
		if ( getPageDetail.status === 'ok' ) {
			this.setState({
				pageDetailApi    : true,
				pageTitle        : getPageDetail.data.title,
				pageContent      : getPageDetail.data.content,
				metaTitle        : getPageDetail.data.meta_title,
				metaKeywords     : getPageDetail.data.meta_keywords,
				metaDescriptions : getPageDetail.data.meta_descriptions
			})
		} else {
			document.location.href = Api.backEndapiUrl;
		}
	}

	render() {
		return (
			<div className="howtosellPage">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{ this.state.metaTitle ? this.state.metaTitle : 'Om Valley Liquor - A place for your thirst' }</title>
					<meta name="keywords" content={ this.state.metaKeywords ? this.state.metaKeywords : 'how to sell, gharbanau' } />
					<meta
					name="description"
					content={ this.state.metaDescriptions ? this.state.metaDescriptions : 'Om Valley Liquor - A place for your thirst' }
					/>
					<meta property="og:url" content={ window.location.href } />
					<meta property="og:type" content="Page" />
					<meta property="og:description" content={ this.state.metaDescriptions ? this.state.metaDescriptions : 'Om Valley Liquor - A place for your thirst' } />
					<meta property="og:title" content={ this.state.pageTitle ? this.state.pageTitle : 'Page' } />
				</MetaTags>
				{
					( this.state.pageDetailApi ) ?
					<div className="howtosellwrap">
						{
							( this.state.pageTitle ) ?
							<div className="banner">
								<div className="image_sec bgColor_sec" key='1' >
									<div className="bannercontent">
										<div className="wrapper wrapper_1200">
											<span className="title-small-banner">{ this.state.pageTitle }</span>
										</div>
									</div>
								</div>
							</div>
							: null
						}

						<div className="innerContent">               
							<div className="wrapper wrapper_1200">
								{ ( this.state.pageContent ) ? renderHTML( this.state.pageContent ) : null }
							</div>
							<div className="socialShareWrap">
								<span className="social-text">
								{utils.Language('lang_socialShare', this.props.language)}
								</span>
								<EmailShareButton url={ this.state.url }>
									<EmailIcon size={20} round={false} />
								</EmailShareButton>
								<FacebookShareButton url={ this.state.url }>
									<FacebookIcon size={20} round={false} />
								</FacebookShareButton>
								<TwitterShareButton url={ this.state.url }>
									<TwitterIcon size={20} round={false} />
								</TwitterShareButton>
								{/* <PinterestShareButton url={ this.state.url }>
									<PinterestIcon size={20} round={false} />
								</PinterestShareButton> */}
							</div>
						</div>
					</div>
					: <Loader />
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(HowToSell);