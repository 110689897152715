// SingleSupplier js
import React, { Component } from 'react';
import webservice from '../services/webservice';
import StarRatings from 'react-star-ratings';
import { Api } from '../common/variables';
import { Link } from 'react-router-dom';
import noImage from '../assets/images/no-image-available.png';
import Pagination from "../components/Pagination";
import MetaTags from 'react-meta-tags';
import NewProduct from '../components/NewProducts';
import { isMobile, isBrowser } from 'react-device-detect';
// Import Swiper React components
import Swiper from 'react-id-swiper';

// import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon } from "react-share";

// Lazy load
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// Langugae
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";
import { removeFromWishlist, addToWishlist, addItemToCart } from '../services/common';
import { setCartCount, setAlertMessage } from '../action/commonAction';

class SingleSupplier extends Component {
	constructor() {
		super()
		this.state = {
			supplierApi: false,
			categoryApi: false,
			initDoneLoader: true,
			active: false,
			isUserLogin: false,
			Offset: 0,
			limit: 15,
			totalProducts: 0,
			categoryid: null,
			cartmessage: null,
			paginationtype: 'list',
			bannerImage: [],
			Longitude: null,
			Latitude: null
		}
	}

	async componentDidMount() {
		let suppliersSlug = this.props.vendorInfos.data.slug;

		const userdetails = localStorage.getItem('userdetail');
		if (userdetails) {
			const details = JSON.parse(userdetails);
			if (details.userID) {
				this.setState({
					isUserLogin: true,
				});
			}
		}

		// parameter
		let params = {
			SupplierSlug: suppliersSlug,
			limit: this.state.limit,
			Offset: this.state.Offset,
			lang: this.props.language,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		// fetch api data
		let getSellerProducts = await webservice.httpPost(Api.method.getSellerProducts, params);

		// Suppliers Products
		if (getSellerProducts.status === 'ok') {
			this.setState({
				supplierApi: true,
				initDoneLoader: false,
				getSellerProducts: getSellerProducts.data,
				Offset: getSellerProducts.data.length,
				totalProducts: getSellerProducts.total_records
			})

			if (getSellerProducts.total_records > 0) {
				let getCategories = await webservice.httpPost(Api.method.getCategoriesDropdownWithProduct, { SellerSlug: suppliersSlug, lang: this.props.language });

				if (getCategories.status === 'ok') {
					this.setState({
						getCategoriesDropdown: getCategories.data,
						categoryApi: true,
					})
				}
			}
		}

		// Suppliers Details
		if ( this.props.vendorInfos.status === 'ok' ) {
			const getSupplierDetails = this.props.vendorInfos;
			if ( isMobile ) {
				// window.location = "gharbanauapp://SupplierProductList/" + getSupplierDetails.data.id;
				if ( document.getElementById( "appLink" ) ) {
					document.getElementById( "appLink" ).href = "gharbanauapp://SupplierProductList/" + getSupplierDetails.data.id;
				}
			}

			this.setState({
				bannerImage: getSupplierDetails.data.BannerArrayImage,
				supplierApi: true,
			})
		}
	}

	filterCategories = async (catid) => {
		this.setState({
			active: true,
			categoryid: catid
		});

		let suppliersSlug = this.props.vendorInfos.data.slug;
		let params = {
			Offset: 0,
			CategoryId: catid,
			SupplierSlug: suppliersSlug,
			limit: this.state.limit,
			lang: this.props.language,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let getSellerProducts = await webservice.httpPost(Api.method.getSellerProducts, params);

		if (getSellerProducts.status === 'ok') {
			this.setState({
				supplierApi: true,
				getSellerProducts: getSellerProducts.data,
				Offset: getSellerProducts.data.length,
				paginationtype: 'filter',
				totalProducts: getSellerProducts.total_records
			})
			this.onPageChanged({ currentPage: 1, pageLimit: this.state.limit });
		}

	}

	onPageChanged = async (data) => {
		let suppliersSlug = this.props.vendorInfos.data.slug;

		let params = {
			SupplierSlug: suppliersSlug,
			limit: this.state.limit,
			lang: this.props.language,
			CategoryId: this.state.categoryid,
			Offset: (data.currentPage - 1) * data.pageLimit,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let getSellerProducts = await webservice.httpPost(Api.method.getSellerProducts, params);

		if (getSellerProducts.status === 'ok') {
			this.setState({
				getSellerProducts: getSellerProducts.data,
				totalProducts: getSellerProducts.total_records,
				totalPages: getSellerProducts.total_pages,
				Offset: getSellerProducts.data.length,
				supplierApi: true
			})
		}
	};

	// Add to cart item
	addItemToCart = async (productID, minQty) => {
		if (minQty === 0) {
			minQty = 1;
		}

		const newdata = await addItemToCart(productID, minQty, this.props.language);
		this.props.setCartCount(newdata.count);
		this.props.setAlertMessage(newdata.message);
	}

	//add to wishlist
	addToWishlist = async (productID) => {
		const newdata = await addToWishlist(productID, this.state.getSellerProducts, this.props.language);

		if (newdata.productsArray) {
			this.setState({
				supplierApi: newdata.productsApi,
				getSellerProducts: newdata.productsArray
			});
		}
		this.props.setAlertMessage(newdata.message);
	}

	//remove from wishlist
	removeFromWishlist = async (productID) => {
		const newdata = await removeFromWishlist(productID, this.state.getSellerProducts, this.props.language);

		this.setState({
			supplierApi: newdata.productsApi,
			getSellerProducts: newdata.productsArray
		});
		this.props.setAlertMessage(newdata.message);
	}

	renderSuppliers() {
		const suppliers = [];
		for (let i = 0; i < 15; i++) {
			// Try avoiding the use of index as a key, it has to be unique!
			suppliers.push(
				<div className="col-lg-3 col-sm-4 col-6" key={i}>
					<div className="categoryList" style={{ boxShadow: 'none', alignItems: 'center', }}>
						<div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px', }}>
							<img src={require('../assets/images/placeholder.png')} alt="productsimage" style={{ width: '30px' }} />
						</div>
						<div style={{ width: '70%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', marginBottom: '5px' }}>
						</div>
						<div style={{ width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', }}>
						</div>
					</div>
				</div>
			);
		}
		return suppliers;
	}

	showLoginPopup() {
		document.getElementById("LoginPopup").click();
	}

	render() {
		// const { photoIndex, isOpen } = this.state;

		const params = {
			navigation: {
				nextEl: '.swiper-button-next.swiper-button-white', // Add your class name for next button
				prevEl: '.swiper-button-prev.swiper-button-white' // Add your class name for prev button
			},
			autoplay: {
				delay: 8000,
			},
			spaceBetween: 0,
			loop: this.state.bannerImage.length > 1 ? true : false,
 			lazy: true,
 			preloadImages: false,
 			watchOverflow: true,
		}

		return (
			<div className="suppliers-single-page category_wrapper">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>
						{this.state.name ? this.state.name : ''}
					</title>
					<meta name="keywords" content="Gharbanau, suppliers" />
					<meta
						name="description"
						content="A place for your thirst"
					/>
					<meta property="og:url" content={window.location.href} />
					<meta property="og:type" content="Supplier Details" />
					<meta property="og:description" content={'A place for your thirst'} />
					<meta property="og:title" content={this.state.name} />
					{
						(this.state.logo_fullpath) ?
							<meta property="og:image" content={this.state.logo_fullpath} />
							: null
					}
				</MetaTags>

				{
					(this.state.bannerImage.length > 0) ?
						<Swiper
							{...params}
							wrapperClass={'SwiperBanner'}
							spaceBetween={0}
							slidesPerView={1}
							onSlideChange={() => console.log('slide change')}
							onSwiper={(swiper) => console.log(swiper)}
						>
						{
							(this.state.bannerImage.length > 0) ?
							this.state.bannerImage.map((data, index) => {
								return (
								<div
								className="banners swiper-slide swiper-lazy"
								// style={{ backgroundImage: `url(${data.BannerImage})` }}
								data-background={data.BannerImage}
								key={index}
								>
									<div className="swiper-lazy-preloader"></div>
									{
									(data.BannerLink) ?
									<a href={data.BannerLink} rel="noopener noreferrer">
										<img
											alt='banner'
											className="swiper-lazy"
											data-src={data.BannerImage}
										/>
										<div className="swiper-lazy-preloader"></div>
									</a>
									:
									<div>
										<img
											alt='banner'
											className="swiper-lazy"
											data-src={data.BannerImage}
										/>
										<div className="swiper-lazy-preloader"></div>
									</div>
									}
								</div>
								);
							})
							: null
						}
						</Swiper>

						: null
				}

				<div className="wrapper wrapper_1200 padding_side_15">
					<div className="row">
						<div className="col-lg-3 col-sm-4 suppliers-contents">
							{
								(this.state.categoryApi) ?
									<div className="categorywrap" ref={this.myRef}>
										<div className="miniTitle-div">
											<h3 className="miniTitle">{utils.Language('lang_productCategories', this.props.language)}</h3>
										</div>
										<div className="sidebar-catlist">
											<ul>
												{
													this.state.getCategoriesDropdown.map((item, index) => {
														return (
															<li className={this.state.active && item.id === this.state.categoryid ? 'active' : null} id={item.id} key={index} onClick={e => this.filterCategories(item.id)}>{true === item.is_child ? <span style={{ paddingLeft: 10 }}> - </span> : null}{item.name}</li>
														)
													})
												}
											</ul>
										</div>
									</div>
									: null
							}
						</div>
						<div className="col-lg-9 col-sm-8">
							<div className="category_wrapper-list">
								{
									(!this.state.initDoneLoader) ?
										<div className="row">
											{
												(this.state.getSellerProducts && this.state.getSellerProducts.length > 0) ?
													this.state.getSellerProducts.map((product, index) => {
														return (
															<div className="col-lg-3 col-sm-4 col-6" key={product.Item_Id}>
																<div className="categoryList" id={product.Item_Id}>
																	{
																		('seller' !== this.props.userType) ?
																			<div className="wishlist-div">
																				{
																					(true === product.wishlistStatus) ?
																						<div class="wishlist wishlist-added" title="Wishlist" onClick={() => { this.removeFromWishlist(product.Item_Id) }}><span className="icon-heart-black icon-heart-red icon-fav"></span></div>
																						:
																						<div class="wishlist" title="Wishlist"onClick={() => { this.addToWishlist(product.Item_Id) }}><span className="icon-heart-black icon-fav" ></span></div>
																				}
																			</div>
																			: null
																	}
																	<Link to={'/products/' + encodeURIComponent(product.slug)} className="link">
																		<div className="imgWrapper">
																			<img src={product.imageUrl ? product.imageUrl : noImage} alt="categoryimage" />
																		</div>
																		<span className="product-name">{product.name}</span>
																		<div className="priceSec">
																			{
																				(product.regularPrice !== product.salePrice && product.salePrice !== '0.00') ?
																					<div><span className="regularprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.regularPrice)}</span>
																						<span className="saleprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.salePrice)}
																							{
																								(product.salePriceValidTill) ?
																									<div className="singleBrands validtilldate">
																										<span className="smallText">({utils.Language('lang_priceValidTill', this.props.language)} {product.salePriceValidTill})</span>
																									</div>
																									: null
																							}
																						</span></div>
																					:
																					<span className="saleprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.regularPrice)}</span>
																			}
																		</div>
																	</Link>

																	<div className="starrating-div">
																	{
																		( product.rating ) ?
																			<StarRatings
																			rating={ parseFloat( product.rating ) }
																			starRatedColor="#ff6f00"
																			starHoverColor="#ff6f00"
																			numberOfStars={5}
																			name='rating'
																			starDimension="20px"
																			starSpacing="0"
																			/>
																		: null
																	}
																	</div>
																	{
																		('seller' !== this.props.userType) ?
																			<div data-mh className="productDetail productDetailnew">
																				<button type="button" className="btn btn-danger btn-add-cart" onClick={() => { this.addItemToCart(product.Item_Id, parseInt(product.minimum_quantity)) }}><span className="icon-cart-add"></span>{utils.Language('lang_addToCart', this.props.language)}</button>
																			</div>
																			: null
																	}
																</div>
															</div>
														)
													})
													:
													<div className="col-lg-12 col-sm-12 col-12 text-center">
														<div className="suppliers-no-products pad-lft-15">
															<h5>{utils.Language('lang_supplierProductNotFound', this.props.language)}</h5>
														</div>

														<NewProduct />
													</div>
											}
										</div>
										: <div className="row">{this.renderSuppliers()}</div>
								}
								<div className="pagination_wrapper">
									{
										(0 !== parseFloat(this.state.totalProducts)) ?
											<span className="pagination-items">{this.state.totalProducts} {utils.Language('lang_itemFound', this.props.language)}</span>
											: null
									}
									{
										(this.state.totalProducts > this.state.limit && 'list' === this.state.paginationtype) ?
											<Pagination
												totalRecords={parseInt(this.state.totalProducts)}
												pageLimit={parseInt(this.state.limit)}
												pageNeighbours={1}
												onPageChanged={this.onPageChanged}
											/>
											: null
									}
									{
										(this.state.totalProducts > this.state.limit && 'filter' === this.state.paginationtype) ?
											<Pagination
												totalRecords={parseInt(this.state.totalProducts)}
												pageLimit={parseInt(this.state.limit)}
												pageNeighbours={1}
												onPageChanged={this.onPageChanged}
											/>
											: null
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: (count) => dispatch(setCartCount(count)),
	setAlertMessage: (message) => dispatch(setAlertMessage(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSupplier);