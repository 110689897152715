export const English = {
	lang_productCategories: 'Product Categories', // for web only
	lang_viewMore: 'View more', // for web only
	lang_allCategories: 'Categories', // for web only
	lang_contactUs: 'Contact us', // for web only
	lang_systemError: 'System error.', // for web only
	lang_404Page: '404! page not found.', // for web only
	lang_emailInUse: 'Email address already in use.', // for web only
	lang_itemFound: 'items found', // for web only
	lang_searchResult: 'Search Results', // for web only
	lang_lastname_req: 'Last Name Required', // for web only
	lang_firstname_req: 'First Name Required', // for web only
	lang_sortBy: 'Sort By:', // for web only
	lang_popularity: 'Popularity', // for web only
	lang_nearestLocation: 'Nearest location', // for web only
	lang_ResetCodeExpire: 'Your code has expired, please re-submit again.', // for web only
	lang_IacceptGharbanau: 'I accept Om Valley Liquors', // for web only
	lang_qnsAnswerBy: 'Other questions answered', // for web only
	lang_techInfo: "Information", // for web only
	lang_cashOnDeliveryTextFooter: 'Make your order now and pay cash on delivery.', // for web only
	lang_cashOnDeliveryTextCart: 'Confirm your order now and pay cash at the time of delivery.', // for web only
	lang_customerSupport: 'CUSTOMER SUPPORT', // for web only
	lang_customerSupportText: 'Our team is ready to serve you the best.', // for web only
	lang_onTimeDelivery: 'ON TIME DELIVERY', // for web only
	lang_onTimeDeliveryText: 'Our goal is to deliver your order as soon as possible.', // for web only
	lang_loginToAddCart: 'Please Login to add in Add to cart.', // for web only
	lang_loginToAddWishlist: 'Please Login to add in wishlist.', // for web only
	lang_followUs: 'Follow Us', // for web only
	lang_gharbanauDotCom: 'Om Valley Liquors', // for web only
	lang_footerLocation: 'Naag Pokhari, Kathmandu, Nepal', // for web only
	lang_footerDownloadText: 'Download Om Valley Liquors Mobile app', // for web only
	lang_bottomFooterImageInfo: 'Images and information used in this website is strictly prohibited to copy or reproduce in any way.', // for web only
	lang_bottomFooterCopyright: 'Copyright', // for web only
	lang_bottomFooterCopyrightdesc: '| All rights reserved. Site developed & maintained by', // for web only
	lang_companyName: 'Tulips Technologies', // for web only
	lang_signWithFB: 'SIGN IN WITH FACEBOOK', // for web only
	lang_quickLinks: 'Quick Links', // for web only
	lang_needLogin: 'You need to login before you can access any member\'s features.', // for web only
	lang_searchEmptyKeyword: 'Search Keyword is Empty', // for web only
	lang_emailPhone: "Email/Phone Number", // for web only
	lang_firstName: "First Name", // for web only
	lang_lastName: "Last Name", // for web only
	lang_productReviews: "Product Reviews", // for web only
	lang_search: 'Search', // for web only
	lang_userPassError: 'Your username or password is incorrect.', // for web only
	lang_PostQuestionsSuccess: 'Your question has been submitted.', // for web only
	lang_shippingAddressDifferent: 'Different Shipping Address', // for web only
	lang_no_shipping: 'No shipping', // for web only
	lang_phoneDuplicate: 'Mobile Number already in use.', // for web only
	lang_SupplierProduct: 'Product by the same supplier', // for web only
	lang_404PageDesc: 'You are here because the url is not found in our system.', // for web only
	lang_404PageDescSec: 'Please check the url or click the following button.', // for web only
	lang_viewOnMap: 'View on map', // for web only
	lang_lastUpdatedOn: 'Last updated on', // for web only
	lang_newsTitle: 'News', // for web only
	lang_newsNotFound: 'Post not found', // for web only
	lang_faqs: 'FAQs', // for web only
	lang_priceValidTill: 'Price valid till', // for web only
	lang_otherNews: 'Other news/blogs', // for web only
	lang_newsAndBlog: 'News and Blogs', // for web only
	lang_readMore: 'Read More', // for web only
	lang_logo: 'Logo',
	lang_Category: 'Category',
	lang_categories: 'Categories',
	lang_featuredProducts: 'Featured Products',
	lang_nofeaturedProducts: 'No featured products available',
	lang_newProducts: 'New Products',
	lang_View: 'View',
	lang_All: 'All',
	lang_viewAll: 'View All',
	lang_mostPopularProducts: 'Most Popular Product',
	lang_home: 'Home',
	lang_about: 'About',
	lang_findSuppliers: 'Find Suppliers',
	lang_howToSell: 'How To Sell',
	lang_termsAndConditions: 'Terms and Conditions',
	lang_privacyPolicy: 'Privacy Policy',
	lang_LogIn: 'Log In',
	lang_LogOut: 'Sign Out',
	lang_signUp: 'Sign Up',
	lang_isEmptyQuantity: 'Please enter quantity',
	lang_cartEntryError: 'Adding items to cart failed.',//cart entry failed
	lang_CartEntrySuccess: 'Item added to cart.',
	lang_CartUpdateSuccess: 'Items in the cart updated.',
	lang_Success: 'Success',
	lang_km: ' km',
	lang_meter: ' meter',
	lang_currency: 'Rs.',
	lang_featureBrand: 'Featured Brands',
	lang_brand: 'Brand',
	lang_products: 'Products',
	lang_LoadMore: 'Load More',
	lang_deleteSuccess: 'Item is removed from the cart.',//Delete success
	lang_deleteError: 'Delete Error',
	lang_updateSuccess: 'Update success',
	lang_updateError: 'Update Error',
	lang_checkoutText: 'Check Out',
	lang_taxText: '*inclusive of all taxes',
	lang_recommendedMethod: 'Payment method',
	lang_cashOnDelivery: 'CASH ON DELIVERY',
	lang_cashOnDeliveryText: 'Confirm your order now and pay cash at the time of delivery.',
	lang_confirmOrder: 'Confirm Order',
	lang_address: 'Address',
	lang_city: 'City',
	lang_addressDetail: 'Address Detail',
	lang_billingAddress: 'Billing Address',
	lang_billingShippingAddress: 'Billing and Shipping Address',
	lang_errorBillingAddress: "Billing address missing",
	lang_errorShippingAddress: "Shipping address missing",
	lang_shippingAddress: 'Shipping Address',
	lang_Edit: 'Edit',
	lang_Cancel: 'Cancel',
	lang_Ok: 'Ok',
	lang_Submit: 'Submit',
	lang_Add: 'Add',
	lang_cancelOrder: 'Do you want to cancel this order?',
	lang_cancelVendorOrder: 'Do want to cancel this item?',
	lang_update: 'Update',
	lang_fullName: "Full Name",
	lang_errorFullname: 'Full Name cannot be empty',
	lang_phoneNumber: 'Phone Number',
	lang_mobileNumber: 'Mobile Number',
	lang_emailAddress: 'Email Address',
	lang_altMobile: 'Alternate Mobile Number',
	lang_altPhone: 'Alternate Phone Number',
	lang_Designation: 'Designation',
	lang_errorMobileNumber: 'The Mobile Number cannot be empty',//
	lang_errorCity: 'The city cannot be empty',//error city
	lang_errorCompanyName: 'The company name cannot be empty',//
	lang_CompanyName: 'Company Name',
	lang_PAN: 'PAN',
	lang_Street: 'Street',
	lang_errorStreet: 'The street name cannot be empty',//
	lang_region: 'Region',
	lang_errorRegion: 'The region name cannot be empty',//error region
	lang_Country: 'Country',
	lang_errorCountry: 'The country name cannot be empty',//error country
	lang_saveAddressSuccess: 'The address is saved',//addres is saved
	lang_gender: 'Gender',
	lang_profileTitle: 'My Profile',
	lang_myCart: 'My Cart',
	lang_profile: 'Profile',
	lang_editProfile: 'Edit Profile',
	lang_addressTitle: 'Address Detail',
	lang_NoAddress: 'Shipping or Billing address is missing.',
	lang_ThanksMsg: 'Thank you for your purchase.',
	lang_ThanksSecondMsg: 'Your order number is',
	lang_MakeAmountReadyMsg: 'Please make the following amount ready at the time of delivery.',
	lang_OrderSummaryLabel: 'Order Summary',
	lang_ViewOrderLabel: 'View Order',
	lang_Continue: 'Continue',
	lang_ContinueShoppingLabel: 'Continue Shopping',
	lang_MoreDetailOrder: 'For more details, track your delivery status order',//order=>under
	lang_NoInternet: 'No internet connection',
	lang_ContinueShopping: 'Continue Shopping',
	lang_CartUpdate: 'Please update the cart first to checkout.',
	lang_CartEmpty: 'There is no item in your cart.',
	lang_CartEmptyMessage: 'Cart is empty, you cannot checkout now.',
	lang_dashboardTitle: 'Dashboard',
	lang_orderpage: 'Orders',
	lang_Wishlist: 'Wishlist',
	lang_addWishlist: 'The product successfully added to the wishlist.',//added 'the'
	lang_removeWishlist: 'The product successfully removed from the wishlist.',//
	lang_viewedProducts: 'Viewed Products',
	lang_myReviews: 'My Reviews',
	lang_emptyViewedProduct: 'You have not viewed any products.',
	lang_emptyUserWishlist: 'You have not added any product in your wishlist.',
	lang_emptyDeliveredItem: 'There is no product delivered yet.',
	lang_emptyReviewRatingMessage: 'Rate the product to submit.',
	lang_orders: 'Orders',
	lang_settingsAccount: 'Account Settings',
	lang_AccountText: 'Account',
	lang_settingsShipping: 'Shipping Settings',
	lang_myProducts: 'My Products',
	lang_productNotFound: 'Product not found',
	lang_supplierProductNotFound: 'This supplier has not submitted any product.',
	lang_otherSupplierProduct: 'Product by other suppliers',
	lang_paymentMethod: 'Payment Method',
	lang_memberSince: 'Member since',
	lang_emptyProducts: 'No Products were added',//
	lang_editAddress: 'Edit My Address',
	lang_defaultBilling: 'Default Billing Address',
	lang_defaultShipping: 'Default Shipping Address',
	lang_nearestLandmark: 'Nearest Landmark',
	lang_sameBillingShipping: 'Shipping Address is the same as Billing Address',//'the'
	lang_yes: "Yes",
	lang_no: "No",
	lang_soldby: "Sold by",
	lang_ratingsReviews: "Ratings & Reviews",
	lang_description: "Description",
	lang_Certification: "Certification",
	lang_Attachment: "Attachment",
	lang_Delivery: "Delivery Options",
	lang_ReturnWarranty: "Return and warranty",
	lang_WarrantyPeriod: "Warranty period",
	lang_WarrantyPolicy: "Warranty policy",
	lang_ExchangePeriod: "Exchange period",
	lang_ExchangePolicy: "Exchange policy",
	lang_NoWarranty: "No Warranty",
	lang_Specification: "Specification",
	lang_footerHome: 'Home',
	lang_footerWishlist: 'Wishlist',
	lang_footerCart: 'Cart',
	lang_footerAccount: 'Account',
	lang_writeReview: 'Write Review',
	lang_orderDetails: 'Order Details',
	lang_btnCancelOrder: 'Cancel this order?',
	lang_btnCancelItem: 'Cancel this item?',
	lang_recentOrders: 'Recent Orders',
	lang_orderDate: 'Date',
	lang_orderItems: 'Items',
	lang_orderDetail: 'Details',
	lang_orderStatus: 'Status',
	lang_orderStatusPending: 'Pending',
	lang_orderStatusProcessing: 'Processing',
	lang_orderStatusShipped: 'Shipped',
	lang_orderStatusDelivered: 'Delivered',
	lang_orderStatusCancelled: 'Cancelled',
	lang_updateStatus: 'Update status of this order?',
	lang_errorRegularPrice: 'The price cannot be empty.',//No Price
	lang_errorCategory: 'Select Category',
	lang_errorProductName: 'The product name cannot be empty.',//no product name
	lang_selectCategory: 'Select Category',
	lang_errorDefaultFee: 'Enter default shipping fee',
	lang_errorDuplicateCategory: 'The selected category was used already',//
	lang_ConfirmDeleteAll: 'Are you sure you want to delete all the items ?',//Are you sure you want to delete all
	lang_submitReview: 'Submit Review',
	lang_ratenReview: 'Rate and Review Your Seller',//&amp; => and
	lang_shippingText: 'Shipping',
	lang_shippingFee: 'Shipping Fee',
	lang_subTotal: 'Subtotal',
	lang_Total: 'Total',
	lang_Apply: 'Apply',
	lang_Order: 'Order',
	lang_addToCart: 'Add to Cart',
	lang_searchProducts: 'Search products',
	lang_totalRecord: 'Total Record',
	lang_homeDelivery: 'Delivery',
	lang_backtohome: 'Back to home',
	lang_saveChanges: 'Save changes',
	lang_errorUsername: 'Username cannot be empty.',
	lang_errorPassword: 'Password cannot be empty.',
	lang_loginMessage: 'You need to log in before you can access any product',
	lang_forgotPass: 'Forgot Password',
	lang_password: 'Password',
	lang_NewPassword: 'New Password',
	lang_ConfirmPassword: 'Confirm Password',
	lang_Alreadylogin: "Already a member? Login.",
	lang_signUpMessage: "Don't have an account yet? Sign up now.",
	lang_emailOrPhone: "Email Address or Phone Number",
	lang_review: "Review",
	lang_purchasedOn: "Purchased on",
	lang_buyNow: "Buy now",
	lang_uploadImage: "Upload image",
	lang_changeImage: "Change image",
	lang_uploadPImage: "Upload the product image",
	lang_uploadCertification: "Upload certification",
	lang_changeCertification: "Change certification",
	lang_uploadCImage: "Upload the Certification image",
	lang_ExtraCImage: "Specification, brochures, etc image",
	lang_Facebook: "Facebook",
	lang_Twitter: "Twitter",
	lang_Instagram: "Instagram",
	lang_CompanyRegistration: "Company Registration",
	lang_AccountInformation: "Account Information",
	lang_BannerImage: "Banner Image",
	lang_AboutCompany: "About Company",
	lang_WhyChooseUs: "Why choose us?",
	lang_CompanyDocument: "Company Document",
	lang_PANRegistration: "PAN Registration",
	lang_ContactPerson: "Contact Person",
	lang_ResetPassword: "Reset Password",
	lang_ResetPasswordIntro: "Please enter your email address where we can send the reset password instructions for your registered account.",
	lang_ResetCode: "Reset Code",
	lang_ResetCodeIntro: "Please enter the reset code you received in your email address.",
	lang_SendResetCode: "Send Reset Code",
	lang_NewPasswordIntro: "Please enter the new password to log in to your account.",
	lang_AddNew: "Add New",
	lang_DefaultFee: "Default Fee",
	lang_DefaultDistance: "Default Distance",
	lang_IncrementPerKM: "Increment Per KM",
	lang_KM: " KM",
	lang_Days: " Days",
	lang_IMBuyer: "I am a Buyer",
	lang_IMSeller: "I am a Seller",
	lang_orderDetailText: "Items and Shipping fee",
	lang_Suppliers: "Suppliers",
	lang_searchSuppliers: "Search Suppliers",
	lang_noSuppliers: "No Suppliers were found.",
	lang_blankAccount: 'Hello user. Welcome to GharBanau!',
	lang_logSignMessage: 'You need to Log in or Sign up first.',
	lang_moreaboutus: 'More about us',
	// lang_terms: 'I accept the terms and conditions of Om Valley Liquors.',
	lang_GeneralInfo: 'General Information',
	lang_productNameEng: 'Product Name in English',
	lang_productNameNep: 'Product Name in Nepali',
	lang_descriptinoEng: 'Description in English',
	lang_descriptinoNep: 'Description in Nepali',
	lang_selectBrand: 'Select Brand',
	lang_selectUnit: 'Select Unit',
	lang_RegularPrice: 'Regular price',
	lang_SalePrice: 'Sale price',
	lang_Unit: 'Unit',
	lang_MinimumOrderQuantity: 'Minimum Order Quantity',
	lang_Attributes: 'Attributes',
	lang_ItemSize: 'Item Size',
	lang_ItemWeight: 'Item Weight',
	lang_ItemWidth: 'Item Width',
	lang_ItemHeight: 'Item Height',
	lang_ItemLength: 'Item Length',
	lang_Dimension: 'Dimension',
	lang_Inventory: 'Inventory',
	lang_SKU: 'SKU',
	lang_stockStatus: 'Stock Status',
	lang_Warranty: 'Warranty',
	lang_WarrantyType: 'Warranty Type',
	lang_WarrantyPeriodEng: 'Warranty Period in English',
	lang_WarrantyPeriodNep: 'Warranty Period in Nepali',
	lang_WarrantyPolicyEng: 'Warranty Policy in English',
	lang_WarrantyPolicyNep: 'Warranty Policy in Nepali',
	lang_Exchange: 'Exchange',
	lang_ExchangeType: 'Exchange Type',
	lang_ExchangePeriodEng: 'Exchange Period in English',
	lang_ExchangePeriodNep: 'Exchange Period in Nepali',
	lang_ExchangePolicyEng: 'Exchange Policy in English',
	lang_ExchangePolicyNep: 'Exchange Policy in Nepali',
	lang_ShippingSetting: 'Shipping Setting',
	lang_ShippingType: 'Shipping Type',
	lang_ShippingInfo: 'Shipping Information',
	lang_DeliveryDays: 'Delivery Time(Days)',
	lang_DefaultShipping: 'Default shipping fee per unit(Rs)',
	lang_DefaultDistanceKM: 'Default distance(Km)',
	lang_IncrementKM: 'Fee increment per kilometer.',
	lang_MinRange: 'From quanity',
	lang_MaxRange: 'To quantity',
	lang_AddShipping: '+ Add shipping fee range',
	lang_questionAnswer: 'Question and Answer',
	lang_Uploading: 'Uploading...',
	lang_save: 'Save',
	lang_shippingFeeRange: 'Shipping fee range',
	lang_tryDifferentKeywords: 'Try different keywords',
	lang_filterProduct: 'Filter product',
	lang_errorAddress: 'The address cannot be empty',//address empty
	lang_Draft: 'Draft',
	lang_Published: 'Published',
	lang_Nepali: 'नेपाली/Nepali',
	lang_English: 'अंग्रेजी/English',
	lang_Help: 'Help',
	lang_QuestionsAbout: 'Questions and Answers about this product',
	lang_PostQuestions: 'Add a question about the product?',
	lang_FillYourQuestion: 'Fill Your Question',
	lang_loginToAsk: 'You need to be logged in before asking any questions.',
	lang_changePassword: 'Change Password',
	lang_oldPassword: 'Old Password',
	lang_newPassword: 'New Password',
	lang_confirmPassword: 'Confirm Password',
	lang_by: 'by',
	lang_Unanswered: 'Unanswered',
	lang_GiveAnswer: 'Give answer',
	lang_noConnectionTitle: 'No internet connection!',
	lang_noConnection: 'Please check your internet connection and try again.',
	lang_Refresh: 'Refresh',
	lang_searchNo: 'Sorry, no matching product found.',
	lang_gotoCart: 'Go to cart',
	lang_conformationCartDelete: 'Are you sure, you want to remove this item from the cart?',//
	lang_loading: 'Loading...',
	lang_switchLanguage: 'भाषा परिवर्तन',
	lang_qty: 'QTY',
	lang_Iaccept: 'I accept',
	lang_on: 'on',
	lang_vendor: 'Vendor',
	lang_placedOn: 'Placed on',
	lang_paidBy: 'Paid by',
	lang_of: 'of',
	lang_emailDuplicate: 'Email has already been registered.',//
	lang_resultsFor: 'Results for: ',
	lang_history: 'History',
	lang_errorResetPassword: 'Fill in the Email Address.',
	lang_noQuestionAnswer: 'There are no questions yet.',//
	lang_GlobalLoading: 'Loading...',
	lang_reviewSucceed: 'Successfully reviewed.',//spelling
	lang_reviewFailed: 'There was an error in reviewing.',//
	lang_ResetCodeEmptyMessage: 'Reset Code Cannot Be Empty',
	lang_ResetCodeInvalid: 'Reset Code Invalid.',
	lang_ResetCodeError: 'Something went wrong. Please retry again!',
	lang_passwordChanged: 'You have successfully changed the Password.',
	lang_passwordResetPasswordError: 'Password and Confirm Password do not match.',
	lang_oldPasswordRequired: 'Old password required.',
	lang_PasswordRequired: 'Password required.',
	lang_confirmPasswordRequired: 'Confirm Password required.',
	lang_errorFullname_shipping: 'Full Name cannot be empty in the Shipping Address',
	lang_errorMobileNumber_shipping: 'Mobile Number cannot be empty in the Shipping Address',//error mobile number in shipping
	lang_errorRegion_shipping: 'The region cannot be empty in the Shipping Address',//Error Region in Shipping
	lang_errorCity_shipping: 'The City cannot be empty in the Shipping Address',//error city in shipping
	lang_errorAddress_shipping: 'The address cannot be empty in the Shipping Address',//Error Address in Shipping
	lang_successPasswordChanged: 'Password changed successfully',
	lang_ques: 'Q.',
	lang_answ: 'A.',
	lang_product: 'Product',
	lang_price: 'Price',
	lang_productUpdate: 'Product updated successfully',
	lang_settingupdate: 'Setting updated successfully',
	lang_invalidLoginCredentials: 'Invalid login credentials.',
	lang_errorFB: 'Error with Facebook login. Please try again!',
	lang_AddAddress: 'Please add your Address.',
	lang_results: 'results',
	lang_price_high_to_low: 'Price high to low',
	lang_price_low_to_high: 'Price low to high',
	lang_nearest_to_farthest: 'Nearest to farthest',
	lang_farthest_to_nearest: 'Farthest to nearest',
	lang_male: 'Male',
	lang_female: 'Female',
	lang_others: 'Others',
	lang_pending: 'Pending',
	lang_processing: 'Processing',
	lang_shipped: 'Shipped',
	lang_delivered: 'Delivered',
	lang_wishlistRemove: 'Do you want to remove this item from wishlist?',
	lang_terms: 'Please accept terms and conditions',
	lang_fullName_req: 'Full Name Required',
	lang_mobileNumber_req: 'Mobile Number Required',
	lang_emailAddress_req: 'Email Address Required',
	lang_password_req: 'Password Required',
	lang_invalidemail: 'Invalid email address.',
	lang_passReset_emptyEmail: 'Enter Email Address',
	lang_passReset_invalidEmail: 'Invalid Email Address',
	lang_passReset_serverError: 'Something Went Wrong Please Retry',
	lang_totalShippingCost: 'Total Shipping Cost',
	lang_sku: 'SKU',
	lang_width: 'Width',
	lang_length: 'Length',
	lang_height: 'Height',
	lang_size: 'Size',
	lang_weight: 'Weight',
	lang_minOrderError: 'Minimum order quantity is',
	lang_rating: 'Rating',
	lang_ratings: 'Ratings',
	lang_cms: 'cms',
	lang_free: 'Free',
	lang_paid: 'Paid',
	lang_sellerWarranty: 'Has Warranty',
	lang_sellernoWarranty: 'Has no warranty',
	lang_hasExchange: 'Has Exchange',
	lang_hasNoExchange: 'Has no Exchange',
	lang_inStock: 'Is in Stock',
	lang_outStock: 'Is Out of Stock',
	lang_or: 'Or',
	lang_youMayLike: 'You may also like to see',
	lang_textCopied: 'Copied',
	lang_invalidOldPAssword: 'Invalid old password',
	lang_in: 'in',
	lang_profileUpdate: 'Profile updated sucessfully',
	lang_addressSave: 'Saved sucessfully',
	lang_state: 'State',
	lang_errorState_shipping: 'Please enter shipping state',
	lang_errorState: 'Please enter state',
	lang_conformationProductDelete: 'Are you sure, you want to remove this product?',
	lang_conformationSettingDelete: 'Are you sure, you want to remove this setting?',
	lang_profileImage: 'Profile Image',
	lang_district: 'District',
	lang_noQuestion: 'No any questions added yet.',
	lang_latestUpdate: 'Latest update',
	lang_verified_supplier: 'Verified',
	lang_verified: 'Verified',
	lang_notVerified: 'Not verified',
	lang_verifiedInDropDown: 'Verified Suppliers',
	lang_pricing: 'Pricing',
	lang_shippingExtraInfo: 'Extra applicable',
	lang_fbLogin: 'Facebook Login',
	lang_DealershipCertificate: 'Dealership Certificate',
	lang_SetLocation: 'Set Location to find local suppliers',
	lang_searchLocation: 'Search Location',
	lang_allSuppliers: 'All Suppliers',
	lang_choose: 'Choose',
	lang_priceList: 'Price List',
	lang_validateCode: 'Validate Code',
	lang_validate: 'Validate',
	lang_error_validation_code: 'Validation code should be six digit.',
	lang_invalid_validation_code: 'Invalid validation code.',
	lang_validateCode_title: 'Enter the six digit number we have sent in your mobile via SMS.',
	lang_changeCompanyAddress: 'Click to set company address',
	lang_ViewAllCategories: 'View All Categories',
	lang_callNow: 'Call',
	lang_socialShare: 'Share',
	lang_callNowSupplier: 'Call Supplier',
	lang_sendMail: 'Send mail',
	lang_tabDelivery: 'Delivery',
	lang_tabWarranty: 'Warranty',
	lang_tabExchange: 'Exchange',
	lang_questionPlaceholder: 'Type your question here and click submit.',
	lang_becomeASeller: 'Become a seller',
	lang_popularSearches: 'Popular Searches',
}
