// Show category list
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import noImage from '../assets/images/no-image-available.png';
import MetaTags from 'react-meta-tags';
import Pagination from "../components/Pagination";
import { isMobile, isBrowser } from 'react-device-detect';

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";

class CategoryList extends Component {
	constructor() {
		super()
		this.state = {
			CategoriesApi : false,
			limit         : 9999,
		}
		this.myDivToFocus  = React.createRef();
	}

	async componentDidMount() {
		window.scrollTo({top: 0});

		if ( isMobile ) {
			// window.location="gharbanauapp://ProductCategories";
			if ( document.getElementById( "appLink" ) ) {
				document.getElementById( "appLink" ).href = "gharbanauapp://ProductCategories";
			}
		}

		let params = {
			ViewType : 'weblist',
			limit    : this.state.limit,
			lang     : this.props.language
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let categories = await webservice.httpPost( Api.method.getAllCategories, params );

		if ( categories.status === 'ok' ) {
			this.setState({
				categories: categories.data,
				totalcats : categories.total,
				limit     : categories.limit,
				CategoriesApi: true,
			})
		}
	}

	onPageChanged = async( data ) => {
		let params = {
			ViewType    : 'weblist',
			limit       : this.state.limit,
			lang        : this.props.language,
			CurrentPage : data.currentPage,
			Offset      : ( data.currentPage - 1 ) * data.pageLimit
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let categories = await webservice.httpPost( Api.method.getAllCategories, params );
		if ( categories.status === 'ok' ) {
			this.setState({
				categories: categories.data,
				totalcats : categories.total,
				limit     : categories.limit,
				CategoriesApi: true,
			})

			this.myDivToFocus.current.scrollIntoView({ 
				behavior: "smooth",
				block: 'start',
			})
		}
	}

	renderCategpries() {
		const categories = [];
		for (let i = 0; i <  this.state.totalcats; i++) {
			// Try avoiding the use of index as a key, it has to be unique!
			categories.push(
				<div className="categoryList" key={i}>
					<div className="imgWrapper" style={{boxShadow: 'none', alignItems: 'center', flexDirection: 'column',}}>
						<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px',}}>
							<img src={ require('../assets/images/placeholder.png') } alt="productsimage" style={{width: '40px'}} />
						</div>
						<div style={{width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px',alignItems: 'center',}}></div>
					</div>
				</div>
			);
		}
		return categories;
	}

	render() {
		return (
			<div className="category_wrapper category_listing_page page-list test" ref={this.myDivToFocus}>
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>
						Categories - A place for your thirst
					</title>
					<meta name="keywords" content="category, gharbanau" />
					<meta
					name="description"
					content="A place for your thirst"
					/>
				</MetaTags>

				<div className="wrapper wrapper_1200 padding_side_15 product-categories">
					<div className="breadcrumb-section">
						<div className="breadcrumb-div">
							<span className="miniTitle breadcrumb-item"><Link to="/" className="link">{ utils.Language( 'lang_home', this.props.language ) } </Link></span>
							<span className="miniTitle breadcrumb-item">{ utils.Language( 'lang_productCategories', this.props.language ) }</span>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
						<div className="category_wrapper-div">
							<div className="d-flex">
							{
								( this.state.CategoriesApi ) ?
									this.state.categories.map( (category,index) => {
										// Check image url
										var imageUrl = noImage;
										if ( category.imageUrl ) {
											imageUrl = category.imageUrl;
										} else if ( category.fullimageUrl ) {
											imageUrl = category.fullimageUrl;
										}

										return(
											<div className="categoryList" key={category.id}>
												<Link to={'/category/'+encodeURIComponent( category.slug )} className="link">
													<div className="imgWrapper">
														<img src={ imageUrl } alt="categoryimage" />
													</div>
													<span>{category.name}</span>
												</Link>
											</div>
										)
									})
								: this.renderCategpries()
							}
							</div>
						</div>
					</div>
					</div>
					<div className="pagination_wrapper">
						{
							( this.state.totalcats ) ?
							<span className="pagination-items">{ this.state.totalcats } { utils.Language( 'lang_itemFound', this.props.language ) }</span>
							: null
						}
						{
							( this.state.totalcats > this.state.limit ) ?
								<Pagination
					                totalRecords={ parseInt( this.state.totalcats ) }
					                pageLimit={ parseInt( this.state.limit ) }
					                pageNeighbours={1}
					                onPageChanged={ this.onPageChanged }
				              	/>
							: null
						}
		            </div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(CategoryList);