// Page js
import React, { Component } from 'react';
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';
import StarRatings from 'react-star-ratings';
import Lightbox from 'react-image-lightbox';
import renderHTML from 'react-render-html';
import MetaTags from 'react-meta-tags';
import Loader from '../../common/Loader';
// import InfiniteCarousel from 'react-leaf-carousel';
import Swiper from 'react-id-swiper';

import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon } from "react-share";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker
} from "react-google-maps";

// Redux set data
import { connect } from "react-redux";

// Language
import utils from '../../common/utils';

const { compose, withProps } = require("recompose");
const MyMapComponent = compose(
	withProps({
		googleMapURL:
			"https://maps.googleapis.com/maps/api/js?key=AIzaSyAvL-t2e8e1GA7PT6rpUh5rqoCq5Cy8Sc8&v=3.exp&libraries=geometry,drawing,places",
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `400px` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withScriptjs,
	withGoogleMap
)(props => (
	<GoogleMap defaultZoom={13} defaultCenter={{ lat: props.lat, lng: props.lng }}>
		<Marker position={{ lat: props.lat, lng: props.lng }} />
	</GoogleMap>
));

class Page extends Component {
	constructor() {
		super()
		this.state = {
			pageDetailApi: false,
			photoIndex: 0,
			isOpen: false,
			url: '',
			latitude: '',
			longitute: '',
			bannerImage: [],
			contact_phone: '',
			contact_person: '',
			content_mobile: '',
			contact_email: '',
			contact_phone_alt: '',
			contact_designation: '',
			aboutcompany: '',
			products: '',
			whychooseus: '',
			contactus: '',
			home:'',
			isUserLogin: false,
			vendor_phone_alt: null,
			vendor_phone: null,
			pageslists: [],
		}
	}

	async componentDidMount() {
		var str = Api.backEndapiUrl;
		var strrepl = str.replace(/\/+$/, '');
		// var pretyurl = strrepl + this.props.match.url.replace('this.props.match.params.slug', '');
		var mySlug = "/" + this.props.match.params.slug;
		var pretyurl = strrepl + this.props.match.url.replace(mySlug, '');
		this.setState({ url: pretyurl.replace('/\/+$/', '') });

		const userdetails = localStorage.getItem('userdetail');
		if (userdetails) {
			const details = JSON.parse(userdetails);
			if (details.userID) {
				this.setState({
					isUserLogin: true,
				});
			}
		}

		let params = {
			lang: this.props.language,
			pageSlug: this.props.match.params.slug,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude,
			supplierSlug: this.props.match.url.split('/')[2]
		}

		let getPageDetail = await webservice.httpPost(Api.method.getSupplierPageDetail, params);

		if (getPageDetail.status === 'ok') {			
			if ( ( 'about-company' === this.props.match.params.slug && false === getPageDetail.data.AboutCompany ) || ( 'why-choose-us' === this.props.match.params.slug && false === getPageDetail.data.WhyChooseUs ) || ( 'contact-us' === this.props.match.params.slug  && false === getPageDetail.data.ContactUs ) ) {
				document.location.href = Api.backEndapiUrl;
			}

			this.setState({
				pageDetailApi: true,
				pageTitle: getPageDetail.data.title,
				pageContent: getPageDetail.data.content,
				metaTitle: getPageDetail.data.meta_title,
				metaKeywords: getPageDetail.data.meta_keywords,
				metaDescriptions: getPageDetail.data.meta_descriptions,
				latitude: getPageDetail.data.latitude,
				longitude: getPageDetail.data.longitude,
				contact_phone: getPageDetail.data.contact_phone,
				contact_person: getPageDetail.data.contact_person,
				contact_email: getPageDetail.data.contact_email,
				user_contact_email: getPageDetail.data.user_contact_email,
				contact_fullname: getPageDetail.data.contact_fullname,
				bannerImage: getPageDetail.data.BannerArrayImage,
				content_mobile: getPageDetail.data.content_mobile,
				contact_phone_alt: getPageDetail.data.contact_phone_alt,
				contact_designation: getPageDetail.data.contact_designation,
				name: getPageDetail.data.name,
				country: getPageDetail.data.country,
				distance: getPageDetail.data.distance,
				city: getPageDetail.data.city,
				distanceType: getPageDetail.data.distanceType,
				district: getPageDetail.data.district,
				logo_fullpath: getPageDetail.data.logo_fullpath,
				rating: getPageDetail.data.rating,
				region: getPageDetail.data.region,
				street: getPageDetail.data.street,
				address_list: getPageDetail.data.addressList,
				vendor_verified: getPageDetail.data.vendor_verified,
				is_subscribed: getPageDetail.data.is_subscribed,
				vendor_phone: getPageDetail.data.vendor_phone,
				vendor_phone_alt: getPageDetail.data.vendor_phone_alt,
				vendor_file: getPageDetail.data.vendor_file,
				facebook: getPageDetail.data.facebook,
				instagram: getPageDetail.data.instagram,
				twitter: getPageDetail.data.twitter,
				website: getPageDetail.data.website,
				aboutcompany: getPageDetail.data.AboutCompany,
				whychooseus: getPageDetail.data.WhyChooseUs,
				products: '1',
				contactus: getPageDetail.data.ContactUs,
				pageslists: getPageDetail.data.PagesLists,
			})

			var myobj = document.getElementById("footer_bottom-right");
			if (true === getPageDetail.data.is_subscribed) {
				myobj.style.display = 'none';
			} else {
				myobj.style.display = 'block';
			}
		} else {
			document.location.href = Api.backEndapiUrl;
		}

		if (document.getElementById("priceList")) {
			document.getElementById("priceList").parentElement.classList.add('long-wrapper');
		}
	}

	showLoginPopup() {
		document.getElementById("LoginPopup").click();
	}

	render() {
		const { photoIndex, isOpen } = this.state;

		const params = {
			navigation: {
				nextEl: '.swiper-button-next.swiper-button-white', // Add your class name for next button
				prevEl: '.swiper-button-prev.swiper-button-white' // Add your class name for prev button
			},
			autoplay: {
				delay: 8000,
			},
			spaceBetween: 0,
			loop: this.state.bannerImage.length > 1 ? true : false,
			lazy: true,
			preloadImages: false,
			watchOverflow: true,
		}

		return (
			<div className="suppliers-single-page category_wrapper">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{this.state.metaTitle ? this.state.metaTitle : 'Om Valley Liquor - A place for your thirst'}</title>
					<meta name="keywords" content={this.state.metaKeywords ? this.state.metaKeywords : 'terms and condition, gharbanau'} />
					<meta property="og:url" content={window.location.href} />
					<meta property="og:type" content="Page" />
					<meta
						name="description"
						content={this.state.metaDescriptions ? this.state.metaDescriptions : 'Om Valley Liquor - A place for your thirst'}
					/>
					<meta property="og:description" content={this.state.metaDescriptions ? this.state.metaDescriptions : 'A place for your thirst'} />
					<meta property="og:title" content={this.state.pageTitle ? this.state.pageTitle : 'Page'} />
				</MetaTags>

				{
					(this.state.pageDetailApi) ?
						<div className="termswrap">
							{
								(this.state.bannerImage.length > 0) ?
									<Swiper
										{...params}
										wrapperClass={'SwiperBanner'}
										spaceBetween={0}
										slidesPerView={1}
										onSlideChange={() => console.log('slide change')}
										onSwiper={(swiper) => console.log(swiper)}
									>
										{
											(this.state.bannerImage.length > 0) ?
												this.state.bannerImage.map((data, index) => {
													return (
														<div
															className="banners swiper-slide swiper-lazy"
															// style={{ backgroundImage: `url(${data.BannerImage})` }}
															data-background={data.BannerImage}
															key={index}
														>
														<div className="swiper-lazy-preloader"></div>
															{
																(data.BannerLink) ?
																	<a href={data.BannerLink} rel="noopener noreferrer">
																		<img
																			alt='banner'
																			className="swiper-lazy"
																			data-src={data.BannerImage}
																		/>
																		<div className="swiper-lazy-preloader"></div>
																	</a>
																	:
																	<div>
																		<img
																			alt='banner'
																			className="swiper-lazy"
																			data-src={data.BannerImage}
																		/>
																		<div className="swiper-lazy-preloader"></div>
																	</div>
															}
														</div>
													);
												})
												: null
										}
									</Swiper>
									: null
							}
							{
								(this.state.pageTitle && this.state.bannerImage.length === 0) ?
									<div className="banner">
										<div className="image_sec bgColor_sec" key="1">
											<div className="bannercontent">
												<div className="wrapper wrapper_1200">
													<span className="title-small-banner">{this.state.pageTitle}</span>
												</div>
											</div>
										</div>
									</div>
									: null
							}

							<div className="innerContent">
								<div className="wrapper wrapper_1200">
									<div className="row">
										<div className="col-lg-3 col-sm-4 suppliers-contents">
										<div className={'show-in-mobile nav-bar nav-bar-single-page'}>
											
												<a className="link" href={this.state.url}>{utils.Language('lang_home', this.props.language)}</a>
												{
													(this.state.products) ?
														<a className={'products' === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/products'}>{utils.Language('lang_products', this.props.language)}</a>
														: null
												}
												{
													(this.state.aboutcompany) ?
														<a className={'about-company' === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/about-company'}>{utils.Language('lang_AboutCompany', this.props.language)}</a>
														: null
												}
												{
													(this.state.whychooseus) ?
														<a className={'why-choose-us' === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/why-choose-us'}>{utils.Language('lang_WhyChooseUs', this.props.language)}</a>
														: null
												}
												{
													(this.state.contactus) ?
														<a className={'contact-us' === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/contact-us'}>{utils.Language('lang_contactUs', this.props.language)}</a>
														: null
												}
												{
													(this.state.pageslists.length > 0) ?
														this.state.pageslists.map((data, index) => {
															return (
																<a className={data.slug === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/' + data.slug} key={index}>{data.title}</a>
															);
														})
														: null
												}
											</div>
										
												<div className="show-in-desktop suppliers-list" style={{ height: 'auto' }}>
													{
														(this.state.logo_fullpath) ?
															<div className="suppliers-top suppliers-logo">
																{(1 === parseInt(this.state.vendor_verified)) ? <span className="varified"><img src={require('../../assets/images/certifications.png')} alt="certificate" className="verified-logo" />{utils.Language('lang_verified_supplier', this.props.language)}</span> : null}
															</div>
															:
															<div className="suppliers-top no-logo">
																{(1 === parseInt(this.state.vendor_verified)) ? <span className="varified"><img src={require('../../assets/images/certifications.png')} alt="certificate" className="verified-logo" />{utils.Language('lang_verified_supplier', this.props.language)}</span> : null}
															</div>
													}
													<div className="suppliers-company-wrapper">
														{
															(this.state.logo_fullpath) ?
																<div className="imgWrapper">
																	<img src={this.state.logo_fullpath} alt="categoryimage" />
																</div>
																: null
														}

														<div className="suppliers-company-name">{this.state.name}</div>

														<div className="suppliers-address">
															<span className="core-address">{this.state.address_list}</span>
														</div>
														{
															((true === this.state.isUserLogin && this.state.vendor_phone) || (true === this.state.isUserLogin && this.state.vendor_phone_alt)) ?
															<div className="suppliers-phone-div">
																<span className="icon-mobile-alt"></span>
																<div className="suppliers-phone">
																	{(this.state.vendor_phone) ? <div className="soldby-phn">
																		<a className="click-to-call" href={"callto:" + this.state.vendor_phone}>{this.state.vendor_phone}</a></div> : null}
																	{(this.state.vendor_phone_alt) ? <div className="soldby-phn"><a className="click-to-call" href={"callto:" + this.state.vendor_phone_alt}> {this.state.vendor_phone_alt}</a></div> : null}
																</div>
															</div>
																: null
														}
														{/* {
															(this.state.distance) ?
																<div className="suppliers-distance-wrapper">
																	<div className="suppliers-distance">
																		<a href={
																			(this.state.latitude && this.state.longitude) ?
																				'https://maps.google.com/?q=' + this.state.name + '&ll=' + this.state.latitude + ', ' + this.state.longitude + '&z=17'
																				:
																				'https://maps.google.com/?q=' + this.state.name + '&daddr=' + this.state.address_list + '&z=17'
																		} target="_blank" rel="noopener noreferrer">
																			<span className="icon icon-map-marker-alt"></span>
																			{Number.parseFloat(this.state.distance).toFixed(2)} {utils.Language('lang_' + this.state.distanceType, this.props.language)}
																		</a>
																	</div>

																	<div className={'view-on-map'}>
																		<a href={
																			(this.state.latitude && this.state.longitude) ?
																				'https://maps.google.com/?q=' + this.state.name + '&ll=' + this.state.latitude + ', ' + this.state.longitude + '&z=17'
																				:
																				'https://maps.google.com/?q=' + this.state.name + '&daddr=' + this.state.address_list + '&z=17'
																		} target="_blank" rel="noopener noreferrer"><span className="icon-map-marker-alt"></span>{utils.Language('lang_viewOnMap', this.props.language)}</a>
																	</div>
																</div>
																: null
														} */}
														<div className="star-ratings">
															{
																( this.state.rating ) ?
																	<StarRatings
																	rating={ parseFloat( this.state.rating ) }
																	starRatedColor="#ff6f00"
																	starHoverColor="#ff6f00"
																	numberOfStars={5}
																	name='rating'
																	starDimension="24px"
																	starSpacing="5px"
																	/>
																: null
															}
														</div>
														<div className="socialLink">
															{
																(this.state.facebook) ?
																	<a className="socialLink-icon socialLink-icon-facebook" href={this.state.facebook} target="_blank" rel="noopener noreferrer"><span className="icon-facebook-f"></span></a>
																	: null
															}
															{
																(this.state.instagram) ?
																	<a className="socialLink-icon socialLink-icon-instagram" href={this.state.instagram} target="_blank" rel="noopener noreferrer"><span className="icon-instagram"></span></a>
																	: null
															}
															{
																(this.state.twitter) ?
																	<a className="socialLink-icon socialLink-icon-twitter" href={this.state.twitter} target="_blank" rel="noopener noreferrer"><span className="icon-twitter"></span></a>
																	: null
															}
														</div>
														{
															(this.state.vendor_file && this.state.vendor_file.length > 0) ?
																<div className="dealership-certificate">
																	<button className="btn btn-primary" type="button" onClick={() => this.setState({ isOpen: true })}>{utils.Language('lang_DealershipCertificate', this.props.language)}</button>
																	{isOpen && (
																		<Lightbox
																			mainSrc={this.state.vendor_file[photoIndex]}
																			nextSrc={this.state.vendor_file[(photoIndex + 1) % this.state.vendor_file.length]}
																			prevSrc={this.state.vendor_file[(photoIndex + this.state.vendor_file.length - 1) % this.state.vendor_file.length]}
																			onCloseRequest={() => this.setState({ isOpen: false })}
																			onMovePrevRequest={() =>
																				this.setState({
																					photoIndex: (photoIndex + this.state.vendor_file.length - 1) % this.state.vendor_file.length
																				})
																			}
																			onMoveNextRequest={() =>
																				this.setState({
																					photoIndex: (photoIndex + 1) % this.state.vendor_file.length
																				})
																			}
																		/>
																	)}
																</div>
																: null
														}
													</div>
													 
													 <div className="suppliers-contact-person">
														{(this.state.contact_fullname) ? <div className="soldby_icon soldby-seller"><span className="icon"><span className="icon-user"></span></span>{this.state.contact_fullname}</div> : null}
														{(this.state.contact_phone) ? <div className="soldby_icon soldby-phn"><a className="click-to-call" href={"callto:" + this.state.contact_phone}><span className="icon"><span className="icon-mobile-alt"></span></span>{this.state.contact_phone}</a></div> : null}
														{(this.state.contact_email) ? <div className="soldby_icon mailing"><a href={"mailto:" + this.state.contact_email} className="soldby_icon-link"><span className="icon"><span className="icon-mailing"></span></span><span className="mailing-text">{this.state.contact_email}</span></a></div> : null}
													</div> 
													 
												 

													<div className="socialShareWrap">
														<span className="social-text">
															{utils.Language('lang_socialShare', this.props.language)}
														</span>
														<EmailShareButton url={this.state.url}>
															<EmailIcon size={20} round={false} />
														</EmailShareButton>
														<FacebookShareButton url={this.state.url}>
															<FacebookIcon size={20} round={false} />
														</FacebookShareButton>
														<TwitterShareButton url={this.state.url}>
															<TwitterIcon size={20} round={false} />
														</TwitterShareButton>
														{/* <PinterestShareButton url={this.state.url}>
															<PinterestIcon size={20} round={false} />
														</PinterestShareButton> */}
													</div>
												</div>
											
											{											
												(this.state.categoryApi && this.state.products) ?
													<div className="categorywrap" ref={this.myRef}>
														<div className="miniTitle-div">
															<h3 className="miniTitle">{utils.Language('lang_productCategories', this.props.language)}</h3>
														</div>
														<div className="sidebar-catlist">
															<ul>
																{
																	this.state.getCategoriesDropdown.map((item, index) => {
																		return (
																			<li className={this.state.active && item.id === this.state.categoryid ? 'active' : null} id={item.id} key={index} onClick={e => this.filterCategories(item.id)}>{true === item.is_child ? <span style={{ paddingLeft: 10 }}> - </span> : null}{item.name}</li>
																		)
																	})
																}
															</ul>
														</div>
													</div>
												: null
											}


												
											
										</div>

										<div className="col-lg-9 col-sm-8">
											<div className={'show-in-desktop nav-bar nav-bar-single-page'}>
												{

													(this.mySlug !== '') ?
													<a className='link' href={this.state.url + '/products'}>{utils.Language('lang_products', this.props.language)} </a>
													:
													<a className='link active-link' href={this.state.url + '/products'}>{utils.Language('lang_products', this.props.language)}</a>
												}
												
												{
													(this.state.aboutcompany) ?
														<a className={'about-company' === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/about-company'}>{utils.Language('lang_AboutCompany', this.props.language)}</a>
														: null
												}
												{
													(this.state.whychooseus) ?
														<a className={'why-choose-us' === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/why-choose-us'}>{utils.Language('lang_WhyChooseUs', this.props.language)}</a>
														: null
												}
												{
													(this.state.contactus) ?
														<a className={'contact-us' === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/contact-us'}>{utils.Language('lang_contactUs', this.props.language)}</a>
														: null
												}
												{
													(this.state.pageslists.length > 0) ?
														this.state.pageslists.map((data, index) => {
															return (
																<a className={data.slug === this.props.match.params.slug ? 'link active-link' : 'link'} href={this.state.url + '/' + data.slug} key={index}>{data.title}</a>
															);
														})
														: null
												}
											</div>

											{
												(this.state.latitude && this.state.longitude) ?
													<div className={'supplier-map-section'}>
														{
															(true === this.state.isUserLogin && true === this.state.is_subscribed) ?
																<div className={'supplier-content-section suppliers-contents suppliers-list'}>
																	<div className={'suppliers-office'}>
																		{(this.state.name) ? <h3> {this.state.name}</h3> : ''}
																		{(this.state.address_list) ? <p>{this.state.address_list}</p> : ''}

																		{
																			(this.state.user_contact_email) || (this.state.vendor_phone) || (this.state.vendor_phone_alt) || (this.state.website) ?
																				<div className="suppliers-phone">
																					{(this.state.user_contact_email) ? <div className="soldby-phn">
																						<a className="" href={"mailto:" + this.state.user_contact_email}><span className="icon-mailing"></span> {this.state.user_contact_email}</a>
																					</div>
																						: null}

																					{(this.state.vendor_phone) ? <div className="soldby-phn">
																						<a className="click-to-call " href={"callto:" + this.state.vendor_phone}><span className="icon-mobile-alt"></span> {this.state.vendor_phone}</a>
																					</div>
																						: null}

																					{(this.state.vendor_phone_alt) ? <div className="soldby-phn">
																						<a className="click-to-call" href={"callto:" + this.state.vendor_phone_alt}><span className="icon-mobile-alt"></span> {this.state.vendor_phone_alt}</a>
																					</div>
																						: null}

																					{(this.state.website) ? <div className="soldby-phn">
																						<a className="" href={this.state.website} target="_blank" rel="noopener noreferrer"><span className="icon-globe"></span> {this.state.website}</a>
																					</div>
																						: null}
																				</div>
																				: null
																		}
																	</div>

																	<div className={'contact-details suppliers-contact-person'}>
																		{(this.state.contact_fullname) ? <div className={'soldby_icon soldby-seller'}><span className="icon"><span className="icon-user"></span></span> {this.state.contact_fullname}</div> : ''}

																		{(this.state.contact_phone) ? <div className={'soldby_icon soldby-seller'}><span className="icon"><span className="icon-mobile-alt"></span></span> {this.state.contact_phone}</div> : null}

																		{(this.state.contact_phone_alt) ? <div className={'soldby_icon soldby-phn'}><a className="click-to-call" href={"callto:" + this.state.vendor_phone_alt}><span className="icon"><span className="icon-mobile-alt"></span></span> {this.state.contact_phone_alt}</a></div> : null}


																		{(this.state.contact_email) ?
																			<div className={'soldby_icon soldby-mailing'}><a className="" href={"mailto:" + this.state.contact_email}><span className="icon"><span className="icon-mailing"></span></span> {this.state.contact_email}</a></div>
																			:
																			null
																		}
																		{(this.state.contact_designation) ? <div className={'soldby_icon soldby-seller'}><span><strong>Designation:</strong> {this.state.contact_designation}</span></div> : null}
																	</div>
																</div>
																: null
														}

														<MyMapComponent
															lat={parseFloat(this.state.latitude)}
															lng={parseFloat(this.state.longitude)}
														/>
													</div>
													: null
											}
											{
												(this.state.pageContent) ?
													<div className={'supplier-content-section'}>
														{renderHTML(this.state.pageContent)}
													</div>
													: null
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						: <Loader />
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect(mapStateToProps, null)(Page);
